import { useQuery } from '@apollo/client';
import { Box, Skeleton, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import moment from 'moment';
import { useNavigate } from 'react-router';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import CustomTableCell from 'src/components/CustomComponents/CustomTableCell';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import GetFlag from 'src/components/shared/GetFlag';
import { ENTRIES } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';
import { getCurrencySymbol } from 'src/utils/helper-functions';

const SkeletonRow = () => (
  <TableRow>
    <CustomTableCell>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Skeleton variant="circular" width={16} height={16} />
        <Skeleton variant="circular" width={18} height={18} />
        <Skeleton variant="text" width={100} height={20} />
      </Box>
    </CustomTableCell>
    <CustomTableCell>
      <Skeleton variant="rectangular" width={100} height={24} sx={{ borderRadius: 1 }} />
    </CustomTableCell>
    <CustomTableCell>
      <Skeleton variant="text" width={60} height={20} />
    </CustomTableCell>
    <CustomTableCell>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Skeleton variant="circular" width={8} height={8} />
        <Skeleton variant="text" width={80} height={20} />
      </Box>
    </CustomTableCell>
    <CustomTableCell>
      <Skeleton variant="text" width={80} height={20} />
      <Skeleton variant="text" width={40} height={15} />
    </CustomTableCell>
    <CustomTableCell>
      <Skeleton variant="circular" width={24} height={24} />
    </CustomTableCell>
  </TableRow>
);

const Entries = () => {
  const { tournamentId, tournament } = useAuth();
  const navigate = useNavigate();

  const { refetch, loading, data } = useQuery(ENTRIES, {
    skip: !tournamentId,
    variables: {
      filter: {
        tournament: tournamentId,
        // status: 'confirm',
        // categoryId: parseInt(categoryID),
        // type: type,
      },
      orderBy: 'DESC',
    },
  });

  return (
    <Box
      sx={{
        p: '15px',
        borderRadius: '8px',
        border: 1,
        borderColor: '#edf1f6',
        position: 'relative',
        background: 'white',
        height: '100%',
      }}
    >
      <Box display="flex" justifyContent="space-between" borderBottom={1} borderColor="#EDF1F6" pb={1}>
        <Box>
          <Typography variant="body2" color="textSecondary">
            Entries
          </Typography>
          {loading ? <Skeleton variant="text" width={100} height={20} /> : <Typography variant="h6">{data?.tournamentRegistrations?.length || 0}</Typography>}
        </Box>
        <Typography variant="body2" color="textSecondary">
          <CustomButton variant="text" size="small" sx={{ fontWeight: '300', fontSize: '13px' }} onClick={() => navigate('/dashboard/entries')}>
            view all
          </CustomButton>
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell sx={{ pt: '20px !important' }}>Participants</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Category</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Amount paid</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Status</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Time</CustomTableCell>
              {/* <CustomTableCell sx={{ pt: '20px !important' }}></CustomTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? [...Array(6)].map((_, index) => <SkeletonRow key={index} />)
              : data?.tournamentRegistrations?.slice(0, 6)?.map((item, index) => (
                  <TableRow key={index} hover sx={{ backgroundColor: index % 2 ? '#FBFBFB' : 'white' }}>
                    <CustomTableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
                        {item?.users?.map((user, index) => (
                          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <GetFlag
                              country={user?.user?.nation}
                              style={{
                                width: '16px',
                                height: '16px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              imgStyle={{
                                borderRadius: '5px',
                              }}
                            />
                            <CustomAvatar src={user?.user?.avatar} seed={`${user?.user?.name} ${user?.user?.surname}`} size={18} />
                            <Typography
                              variant="font15"
                              sx={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >{`${user?.user?.name?.charAt(0)?.toUpperCase()}. ${user?.user?.surname}`}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </CustomTableCell>
                    <CustomTableCell>
                      <CustomTooltip title={item?.categoryId?.category?.name} placement="top">
                        <CustomChip
                          size="small"
                          variant="secondary"
                          sx={{
                            color: 'table.body.dark',
                            borderColor: '#EDF1F6',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              maxWidth: '100px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            fontSize="12px"
                          >
                            {item?.categoryId?.category?.name}
                          </Typography>
                        </CustomChip>
                      </CustomTooltip>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography sx={{ color: '#0A2540', fontSize: '12px' }}>{item?.amount ? `${item?.amount?.toFixed(2)}${getCurrencySymbol(tournament?.currency)}` : '-'}</Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            bgcolor: item?.status === 'confirm' ? 'success.main' : item?.status === 'pending' ? 'info.main' : item?.status === 'payment_pending' ? 'error.main' : 'error.main',
                          }}
                        />
                        <Typography color="#0A2540" fontSize="12px">
                          {item?.status === 'confirm' ? 'Confirmed' : item?.status === 'pending' ? 'Pending' : item?.status === 'payment_pending' ? 'Pending payment' : 'Withdrawn'}
                        </Typography>
                      </Box>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography color="#0A2540" fontSize="12px" sx={{ width: '80px' }}>
                        {moment(item?.createdAt).format('DD MMM YYYY')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" fontSize="10px">
                        {moment(item?.createdAt).format('HH:mm')}
                      </Typography>
                    </CustomTableCell>
                    {/* <CustomTableCell>
                      <CustomButton variant="text" size="small">
                        <Info sx={{ fontSize: '16px', color: 'rgba(10, 37, 64, 0.35)' }} />
                      </CustomButton>
                    </CustomTableCell> */}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Entries;
