import { gql } from '@apollo/client';

const UPDATE_MANUAL_MATCH = gql`
  mutation updateMatch($id: Int!, $entry1: Int!, $entry2: Int!, $tournament: Int!, $tournamentCategory: Int!, $court: Int, $time: String, $date: DateTime) {
    updateMatch(updateMatchInput: { id: $id, entry1: $entry1, entry2: $entry2, tournament: $tournament, tournamentCategory: $tournamentCategory, court: $court, time: $time, date: $date }) {
      id
    }
  }
`;

export default UPDATE_MANUAL_MATCH;
