import { gql } from '@apollo/client';

const UPDATE_MULTIPLE_MANUAL_MATCHES = gql`
  mutation updateMultipleMatches($updateMatchInput: [UpdateMatchInput!]!) {
    updateMultipleMatches(updateMatchInput: $updateMatchInput) {
      id
    }
  }
`;
export default UPDATE_MULTIPLE_MANUAL_MATCHES;
