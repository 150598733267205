import { gql } from 'src/__generated__/gql';

const CREATE_TOURNAMENT_TEAM_MEMBER = gql(`
  mutation createTournamentTeamMember($registrationRequest: Int!, $categoryId: Int!, $status: String!, $user: Int!, $team: Float!, $tournament: Int!) {
    createTournamentTeamMember(createTournamentTeamMemberInput: { registrationRequest: $registrationRequest, categoryId: $categoryId, status: $status, user: $user, team: $team, tournament: $tournament }) {
      id
    }
  }
`);

export default CREATE_TOURNAMENT_TEAM_MEMBER;
