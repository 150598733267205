import { Box, Container, Grid } from '@material-ui/core';
import { DashboardOnboarding } from '../onboarding/DashboardOnboarding';
import { TournamentHeader } from './TournamentHeader';
import { FactSheet } from './FactSheet';
import ParticipantsList from './ParticipantsList';
import TransactionsList from './TransactionsList';
import ActivityLogs from './ActivityLogs';
import LookingForPartner from './LookingForPartner';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { settings } from 'nprogress';
import useSettings from 'src/hooks/useSettings';
import Tips from './Tips';
import Entries from './Entries';
import useAuth from 'src/hooks/useAuth';
import PageContainer from 'src/components/CustomComponents/PageContainer';

const Dashboard = () => {
  const [openTips, setOpenTips] = React.useState(true);
  const { settings } = useSettings();
  const { tournament } = useAuth();
  const facts = tournament?.facts?.filter((fact) => !!fact?.factSheet?.fieldTitle && !!fact?.answer) ?? [];

  return (
    <React.Fragment>
      <Helmet>
        <title>Tournated Manager: Tournament Overview</title>
      </Helmet>
      <PageContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <TournamentHeader />
          {openTips && <Tips setOpenTips={setOpenTips} openTips={openTips} />}
          <Grid container spacing="20px">
            <Grid item md={4} xs={12} sx={{ flexGrow: 1 }}>
              <ParticipantsList />
            </Grid>
            <Grid item md={8} xs={12} sx={{ flexGrow: 1 }}>
              <Entries />
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item md={8} xs={12} sx={{ flexGrow: 1 }}>
              <TransactionsList />
            </Grid>
            <Grid item md={4} xs={12} sx={{ flexGrow: 1 }}>
              <LookingForPartner />
            </Grid>
          </Grid>
          {facts?.length > 0 && <FactSheet facts={facts} />}

          <ActivityLogs />
          {/* <DashboardOnboarding />

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} lg={8}>
          <FactSheet />
          <ParticipantsList />
          <TransactionsList />
          <ActivityLogs />
        </Grid>

        <Grid item xs={12} lg={4}>
          <Box sx={{ position: 'sticky', top: 24 }}>
            <LookingForPartner />
          </Box>
        </Grid>
      </Grid> */}
        </Box>
      </PageContainer>
    </React.Fragment>
  );
};

export default Dashboard;
