import { useQuery } from '@apollo/client';
import type { Theme } from '@material-ui/core';
import { Box, Divider, Drawer, IconButton, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FileCopy } from '@material-ui/icons';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { League, Tournament } from 'src/__generated__/graphql';
import Scrollbar from 'src/components/Scrollbar';
import { ALL_LEAGUES, SIDEBAR_TOURNAMENTS } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';
import useQueryParams from 'src/hooks/useQueryParams';
import ChevronLeftIcon from '../../../icons/ChevronLeft';
import ChevronRightIcon from '../../../icons/ChevronRight';
import CustomButton from '../../CustomComponents/CustomButton';
import CopyModal from '../CopyModal';
import LanguagePopover from '../LanguagePopover';
import LeagueSelection from './LeagueSelection';
import SidebarMenu from './SidebarMenu';
import TournamentSelection from './TournamentSelection';
import { isEmpty } from 'lodash';
import HelpMenu from '../HelpMenu';
import { ReactSVG } from 'react-svg';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = ({ onMobileClose, openMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setTournamentId, leagueId, tournamentId } = useAuth();

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [selectedTournament, setSelectedTournament] = useState<Tournament>(null);
  const [league, setLeague] = useState<League>(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const debouncedSearch = useDebouncedTerm(searchTitle, 600);
  const { setParam, getParam } = useQueryParams();
  const id = getParam('tournament') as string;
  const leagueIdParam = getParam('leagueId') as string;
  const preselectTournamentId = id ? parseInt(id) : tournamentId;
  const { t } = useTranslation();

  const { data: leagues, loading: leagueLoading } = useQuery(ALL_LEAGUES, {
    onCompleted(data) {
      if (data?.allLeaguesForManager?.length > 0 && !leagueId && !tournamentId && !id) {
        setLeague(leagueIdParam ? data?.allLeaguesForManager?.find((league) => league?.id === parseInt(leagueIdParam)) : data?.allLeaguesForManager?.[0]);
        setTournamentId(null, leagueIdParam ? parseInt(leagueIdParam) : data?.allLeaguesForManager?.[0]?.id);
      }
    },
  });

  const { data, loading, refetch } = useQuery(SIDEBAR_TOURNAMENTS, {
    skip: !leagueId || !leagues,
    variables: {
      page,
      limit: 10,
      filter: {
        ...(debouncedSearch && { title: debouncedSearch }),
        league: leagueId,
      },
    },
    onCompleted: (data) => {
      const tournamentData = data?.allTournamentNames?.tournaments ?? [];
      setHasMore(tournamentData?.length > 0);
      setTournaments((prevTournaments) => {
        const newTournaments = tournamentData?.filter((tournament) => !prevTournaments.find((prevTournament) => prevTournament?.id === tournament?.id));
        return [...prevTournaments, ...newTournaments];
      });
      const activeTournaments = tournamentData?.filter((tournament) => tournament?.status === 'active');
      if (page === 0 && tournamentData?.length > 0 && !tournamentId && !isEmpty(activeTournaments) && !leagueIdParam) {
        setTournamentId(activeTournaments?.[0]?.id);
        setSelectedTournament(activeTournaments?.[0]);
        setParam({ tournament: String(activeTournaments?.[0]?.id), leagueId: '' });
        navigate('/dashboard');
      } else if (page === 0 && isEmpty(activeTournaments) && location.pathname !== '/dashboard/all-tournaments/new') {
        navigate('/dashboard/onboarding');
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  // Replace the existing query with this
  const { loading: isLoadingParamTournament } = useQuery(SIDEBAR_TOURNAMENTS, {
    skip: !preselectTournamentId || tournaments?.find((tournament: Tournament) => tournament?.id === preselectTournamentId) || !leagues,
    variables: {
      filter: {
        ...(debouncedSearch && { title: debouncedSearch }),
        id: preselectTournamentId,
      },
    },
    onCompleted: (data) => {
      setTournaments((prevTournaments) => [...(data?.allTournamentNames?.tournaments ?? [])]);
      if (!isEmpty(data?.allTournamentNames?.tournaments)) {
        setSelectedTournament(data?.allTournamentNames?.tournaments?.[0]);
        setLeague({ ...data?.allTournamentNames?.tournaments?.[0]?.league?.league, platform: data?.allTournamentNames?.tournaments?.[0]?.platform });
        setTournamentId(data?.allTournamentNames?.tournaments?.[0]?.id, data?.allTournamentNames?.tournaments?.[0]?.league?.league?.id);
      }
      // setInitialLoadComplete(true);
    },
    fetchPolicy: 'network-only',
  });

  let tournamentIdParam = tournamentId ? `?tournament=${tournamentId}` : '';
  useEffect(() => {
    if (tournamentId && !id && location.pathname !== '/dashboard/all-tournaments/new' && !leagueIdParam) {
      setParam({ tournament: String(preselectTournamentId) });
    }
    // if (data?.allTournamentNames?.tournaments?.length === 0 && tournaments?.length === 0) {
    //   navigate('/dashboard/control-panel/instructions');
    // } else
    // if (!tournamentId && !id && location.pathname !== '/dashboard/all-tournaments/new' && tournaments?.length > 0) {
    //   navigate('/dashboard/all-tournaments');
    // }
    if (location.pathname === '/dashboard/all-tournaments/new' && !id) {
      setSelectedTournament(null);
      // setTournamentId(null, null);
    }
  }, [tournamentId, preselectTournamentId, tournaments, id, leagueIdParam, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/dashboard/all-tournaments/new' && leagueIdParam) {
      setLeague(leagues?.allLeaguesForManager?.find((league) => league?.id === parseInt(leagueIdParam)) || null);
      setTournamentId(null, parseInt(leagueIdParam));
    }
  }, [location.pathname, leagueIdParam, leagues]);

  // refetch();
  useEffect(() => {
    // refetch();
    setTournaments([]);
    setHasMore(true);
    if (location.pathname === '/' || location.pathname === '') {
      navigate('/dashboard/');
    }
  }, []);

  const [sideBarWidth, setSideBarWidth] = useState(250);
  const [openSideBarState, setOpenSideBarState] = useState(true);
  const [copyModal, setCopyModal] = useState(false);

  const handleOpenSideBar = () => {
    setOpenSideBarState(!openSideBarState);
    if (!openSideBarState) {
      setSideBarWidth(250);
    } else {
      setSideBarWidth(88);
    }
  };

  const handleCopyTournament = () => {
    setCopyModal(true);
  };

  const content = (
    <Fragment>
      {/* SideBar header */}
      <Box
        sx={{
          position: 'sticky',
          display: 'block',
          background: '#ffff',
          top: 0,
          zIndex: 9,
          px: '10px',
        }}
      >
        <CustomButton
          variant="outline"
          size="medium"
          shape="circle"
          sx={{
            position: 'absolute',
            right: '-15px',
            bottom: -12,
            maxWidth: '28px',
            maxHeight: '28px',
            minWidth: '28px',
            minHeight: '28px',
            padding: '6px',
            borderRadius: '8px',
            gap: '8px',
            display: lgUp ? 'flex' : 'none',
            border: '1px solid #EFEFF0',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
          }}
          onClick={handleOpenSideBar}
        >
          {!openSideBarState ? <ChevronRightIcon fontSize="small" sx={{ color: '#0A2540' }} /> : <ChevronLeftIcon fontSize="small" sx={{ color: '#0A2540' }} />}
        </CustomButton>
        <RouterLink to="/dashboard">
          <Box
            sx={{
              borderBottom: '1px solid #EFEFF0',
              height: '60px',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: openSideBarState ? 'flex-start' : 'center',
            }}
          >
            <Box component="img" src={openSideBarState ? '/images/tournatedLogoBig.svg' : '/images/tournated-icon.svg'} sx={{ height: '25px' }} />
          </Box>
        </RouterLink>
      </Box>

      {/* SideBar Items */}
      <Scrollbar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 4.5,
            mt: 2,
            alignItems: '',
            padding: openSideBarState ? '0 10px' : '0px 8px',
            justifyContent: 'space-between',
            height: 'calc(100vh - 135px)',
            // overflowY: "auto",
            // px:1
          }}
        >
          <Box>
            <LeagueSelection
              leagueLoading={leagueLoading}
              loading={loading}
              openSideBarState={openSideBarState}
              league={league}
              leagues={leagues?.allLeaguesForManager || []}
              setLeague={setLeague}
              setSelectedTournament={setSelectedTournament}
              setPage={setPage}
              setSearchTitle={setSearchTitle}
              setTournaments={setTournaments}
              setHasMore={setHasMore}
            />

            <TournamentSelection
              tournaments={tournaments}
              loading={loading}
              setSelectedTournament={setSelectedTournament}
              setLeague={setLeague}
              setPage={setPage}
              tournamentIdParam={tournamentIdParam}
              openSideBarState={openSideBarState}
              selectedTournament={selectedTournament}
              setSearchTitle={setSearchTitle}
              hasMore={hasMore}
              setHasMore={setHasMore}
              isLoadingParamTournament={isLoadingParamTournament}
            />

            {/* SideBar Dropdown Items */}
            <SidebarMenu selectedTournament={selectedTournament} openSideBarState={openSideBarState} onMobileClose={onMobileClose} />
          </Box>
          <Box sx={{ display: { xs: 'flex', lg: 'none' }, flexDirection: 'column', gap: '10px', mb: 2 }}>
            <Divider sx={{ borderColor: 'rgba(237, 241, 246, 1)' }} />
            <CustomButton
              variant="menu"
              size="medium"
              onClick={() => {
                navigate('/dashboard/all-tournaments');
              }}
              selected={location.pathname === '/dashboard/all-tournaments'}
              sx={{ gap: '12px', justifyContent: 'flex-start' }}
            >
              <ReactSVG src="/images/tournament.svg" style={{ width: '20px', height: '20px' }} />
              All Tournaments
            </CustomButton>
            <HelpMenu />
          </Box>
          {selectedTournament && (
            <Tooltip title={t('Make a copy of selected tournament')} placement="top" arrow>
              <Box sx={{ display: 'inline-flex', justifyContent: 'center', paddingBottom: '10px' }}>
                {openSideBarState ? (
                  <CustomButton
                    variant="secondary"
                    size="medium"
                    onClick={handleCopyTournament}
                    sx={{ width: '100%', background: '#FAFAFA', color: '#425466', fontWeight: 500, border: '1px solid #EDF1F6' }}
                  >
                    {t('Copy Tournament')}
                  </CustomButton>
                ) : (
                  <IconButton onClick={handleCopyTournament} sx={{ color: '#86909F', padding: '8px' }}>
                    <FileCopy fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          )}
          <Box
            sx={{
              display: {
                lg: 'none',
                xs: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '10px',
              },
              width: '100%',

              mt: 1,
            }}
          >
            <Box sx={{ border: '1px solid #eee', borderRadius: '6px' }}>
              <LanguagePopover />
            </Box>
          </Box>
        </Box>
      </Scrollbar>
      <CopyModal isOpen={copyModal} setIsOpen={setCopyModal} />
    </Fragment>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={openSideBarState}
        PaperProps={{
          sx: {
            // backgroundColor: "#171819",
            position: 'relative',
            zIndex: 1101,
            overflowY: 'initial',
            borderRight: '1px solid #EFEFF0',

            // height: "calc(100% - 64px) !important",
            // top: "80px !Important",
            // width: 280,
          },
        }}
        sx={{ width: sideBarWidth }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          // backgroundColor: "#171819",
          position: 'relative',
          width: 280,
          pb: 2,
          borderRight: '1px solid #EFEFF0',
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
