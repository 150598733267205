/* eslint-disable react/react-in-jsx-scope */
import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthLayout from './components/authentication/AuthLayout';
import LiveStandings from './pages/dashboard/LiveStandings';
import TournamentResults from './pages/dashboard/TournamentResults';
import ProtectedRoute from './utils/ProtectedRoute';
import { GroupsProvider } from './contexts/GroupsContext';
import { DrawsProvider } from './contexts/DrawsContext';
import Dashboard from './components/dashboard/Dashboard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Component {...props} />
  </Suspense>
);

// Maintenance page
const MaintenancePage = Loadable(lazy(() => import('./pages/maintenance/MaintenancePage')));

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Dashboard pages
const Instructions = Loadable(lazy(() => import('./pages/dashboard/Instructions')));
const OnboardingPage = Loadable(lazy(() => import('./pages/dashboard/OnboardingPage')));
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// New Tournament Pages
const PublishTournament = Loadable(lazy(() => import('./pages/dashboard/PublishTournament')));
const ConfigureSettings = Loadable(lazy(() => import('./pages/dashboard/ConfigureSettings')));
const StageCourtsSettings = Loadable(lazy(() => import('./pages/dashboard/StageCourtsSettings')));
const SettingsLayout = Loadable(lazy(() => import('./pages/dashboard/SettingsLayout')));
// Tournament Pages
const AllTournaments = Loadable(lazy(() => import('./pages/dashboard/Tournaments')));
const ParticipantsData = Loadable(lazy(() => import('./pages/dashboard/ParticipantsData')));
const Transactions = Loadable(lazy(() => import('./pages/dashboard/Transactions')));

const Documents = Loadable(lazy(() => import('./pages/dashboard/Documents')));
const AddDocument = Loadable(lazy(() => import('./pages/dashboard/AddDocument')));
const OrderOfPlay = Loadable(lazy(() => import('./pages/dashboard/OrderOfPlay')));
const Groups = Loadable(lazy(() => import('./pages/dashboard/Groups')));
// const GroupsCard = Loadable(lazy(() => import('./components/dashboard/groupsCard/GroupsCard')));
const Partners = Loadable(lazy(() => import('./pages/dashboard/Partners')));
const UnderConstruction = Loadable(lazy(() => import('./pages/UnderConstruction')));
const Requests = Loadable(lazy(() => import('./pages/dashboard/Requests')));
const Entries = Loadable(lazy(() => import('./pages/dashboard/Entries')));
const Withdrawls = Loadable(lazy(() => import('./pages/dashboard/Withdrawls')));
const AddEntries = Loadable(lazy(() => import('./pages/dashboard/AddEntries')));
const Draws = Loadable(lazy(() => import('./pages/dashboard/Draws')));
const Rankings = Loadable(lazy(() => import('./pages/dashboard/Rankings')));
const ListResults = Loadable(lazy(() => import('./pages/dashboard/ListResults')));
const CustomMetrics = Loadable(lazy(() => import('./pages/dashboard/CustomMetrics')));
const Pairings = Loadable(lazy(() => import('./pages/dashboard/Pairings')));
const PairingsStandings = Loadable(lazy(() => import('./pages/dashboard/PairingsStandings')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    element: <AuthLayout />,
    children: [
      {
        path: 'maintenance-code',
        element: <MaintenancePage />,
      },
      {
        path: 'login',
        element: <Login />,
      },

      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset/:email',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
    ],
  },

  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        // element: <Instructions />,
        element: <Dashboard />,
      },
      {
        path: 'onboarding',
        element: <OnboardingPage />,
      },
      {
        path: 'control-panel',
        children: [
          {
            path: 'instructions',
            element: <Instructions />,
          },
        ],
      },
      {
        path: 'all-tournaments',
        children: [
          {
            index: true,
            element: <AllTournaments />,
          },
          {
            path: 'new',
            element: <SettingsLayout />,
          },
          {
            path: 'edit/:id?',
            element: <SettingsLayout />,
          },
          {
            path: 'configure-settings/:id?',
            element: <ConfigureSettings />,
          },
          // {
          //   path: "edit/:id?",
          //   element: <EditTournaments />,
          // },
        ],
      },
      {
        path: 'general-settings',
        children: [
          {
            index: true,
            element: <SettingsLayout />,
          },
        ],
      },
      {
        path: 'participants',
        children: [
          {
            index: true,
            element: <ParticipantsData />,
          },
        ],
      },
      {
        path: 'transactions',
        children: [
          {
            index: true,
            element: <Transactions />,
          },
        ],
      },

      {
        path: 'documents',
        children: [
          {
            index: true,
            element: <Documents />,
          },
          {
            path: 'add/:id?',
            element: <AddDocument />,
          },
        ],
      },
      {
        path: 'live-standings',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="displayLiveStandings">
                <LiveStandings />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'order-of-play',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="displayOrderPlay">
                <OrderOfPlay />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'tournament-results',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="displayTournamentResults">
                <Rankings />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'list-results',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="displayListResults">
                <ListResults />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'custom-metrics',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="displayListResults">
                <CustomMetrics />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'pairings',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="isEnablePairings">
                <Pairings />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'pairings-standings',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="isEnablePairingStandings">
                <PairingsStandings />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'pending-results',
        children: [
          {
            index: true,
            element: <TournamentResults />,
          },
          //     {
          //       path: 'add/:id?',
          //       element: <AddTournamentResults />,
          //     },
        ],
      },
      {
        path: 'groups',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="displayGroups">
                <GroupsProvider>
                  <Groups />
                </GroupsProvider>
              </ProtectedRoute>
            ),
          },
          {
            path: ':id?',
            element: <Groups />,
          },
          // {
          //   path: 'add-new-group',
          //   element: <AddNewGroup />,
          // },
        ],
      },
      {
        path: 'configure-settings',
        children: [
          {
            index: true,
            element: <ConfigureSettings />,
          },
        ],
      },
      {
        path: 'resource-settings',
        children: [
          {
            index: true,
            element: <StageCourtsSettings />,
          },
        ],
      },
      {
        path: 'partners',
        children: [
          {
            index: true,
            element: <Partners />,
          },
        ],
      },
      {
        path: 'requests',
        element: <Requests />,
      },

      {
        path: 'entries',
        children: [
          {
            index: true,
            element: <Entries />,
          },
          {
            path: 'add/:id?',
            element: <AddEntries />,
          },
        ],
      },
      {
        path: 'withdrawals',
        children: [
          {
            index: true,
            element: <Withdrawls />,
          },
          // {
          //   path: "add/:id?",
          //   element: <AddEntries />,
          // },
        ],
      },
      {
        path: 'draws',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute check="displayDraws">
                <DrawsProvider>
                  <Draws />
                </DrawsProvider>
              </ProtectedRoute>
            ),
            // element: <DrawsTemp />,
          },
          // {
          //   path: 'add-new-draw',
          //   element: <AddNewDraw />,
          // },
        ],
      },
      {
        path: 'account',
        element: <Account />,
      },
    ],
  },

  {
    path: '*',
    // element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      },

      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      // {
      //   path: "*",
      //   element: <NotFound />,
      // },
      {
        path: '*',
        element: <UnderConstruction />,
      },
    ],
  },
];

export default routes;
