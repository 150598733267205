import { gql } from '@apollo/client';

const CREATE_TOURNAMENT_FACT_SHEETS = gql`
  mutation createTournamentFactSheets($createTournamentFactSheetInput: [CreateTournamentFactSheetInput!]) {
    createTournamentFactSheets(createTournamentFactSheetInput: $createTournamentFactSheetInput) {
      id
    }
  }
`;

export default CREATE_TOURNAMENT_FACT_SHEETS;
