import { Box, Typography, useTheme } from '@material-ui/core';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { helpArray } from 'src/constants';
import ChevronDown from 'src/icons/ChevronDown';
import CustomButton from '../CustomComponents/CustomButton';
import CustomPopover from '../CustomComponents/CustomPopover';
import { useMediaQuery } from '@material-ui/core';

const HelpMenu: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CustomPopover
      modalOnMobile
      triggerEl={
        <CustomButton
          variant="menu"
          size={isMobile ? 'medium' : 'small'}
          sx={{
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'flex-start',
            'svg path': {
              fill: '#86909F',
            },
            '&:hover': {
              '& .text-class': {
                color: '#FF5733',
              },
              'svg path': {
                fill: '#FF5733',
              },
            },
          }}
        >
          <ReactSVG src="/images/help.svg" style={{ paddingTop: '2px' }} />
          <Typography sx={{ color: '#86909F', ml: { xs: '12px', md: 1 }, mt: '1px', textTransform: 'capitalize' }} variant="tableHeadFont" className="text-class">
            {t('Help')}
          </Typography>
          <ChevronDown sx={{ fontSize: '16px', color: '#86909F', ml: 1, mt: '1px' }} />
        </CustomButton>
      }
    >
      {(onClose) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {helpArray.map((item, index) => (
              <CustomButton
                size="small"
                variant="menu"
                onClick={() => {
                  if (item?.type === 'redirect') {
                    window.open(item?.link, '_blank');
                  }
                  if (item?.type === 'open') {
                    window.location.href = `mailto:${encodeURIComponent(item?.link)}`;
                  }
                  onClose();
                }}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {item?.title}
              </CustomButton>
            ))}
          </Box>
        );
      }}
    </CustomPopover>
  );
};

export default HelpMenu;
