import { useQuery } from '@apollo/client';
import { Box, Skeleton, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { Info, MoreHoriz } from '@material-ui/icons';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import CustomTableCell from 'src/components/CustomComponents/CustomTableCell';
import GetFlag from 'src/components/shared/GetFlag';
import { PARTNER_SEARCHES_WITH_VALIDATIONS } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';

const SkeletonRow = () => (
  <TableRow>
    <CustomTableCell>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Skeleton variant="circular" width={18} height={18} />
        <Stack>
          <Skeleton variant="text" width={100} height={16} />
          <Skeleton variant="text" width={80} height={14} />
        </Stack>
      </Box>
    </CustomTableCell>
    <CustomTableCell align="right">
      <Stack direction="row" gap="5px" justifyContent="flex-end">
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="circular" width={24} height={24} />
      </Stack>
    </CustomTableCell>
  </TableRow>
);

const LookingForPartner = () => {
  const { tournamentId, tournament } = useAuth();
  const navigate = useNavigate();
  const { loading, data } = useQuery(PARTNER_SEARCHES_WITH_VALIDATIONS, {
    skip: !tournamentId,
    variables: {
      filter: {
        tournament: tournamentId,
      },
    },
  });

  return (
    <Box
      sx={{
        p: '15px',
        borderRadius: '8px',
        border: 1,
        borderColor: '#edf1f6',
        position: 'relative',
        background: 'white',
        height: '100%',
      }}
    >
      <Box display="flex" justifyContent="space-between" borderBottom={1} borderColor="#EDF1F6" pb={1}>
        <Box>
          <Typography variant="body2" color="textSecondary">
            Looking for a partner
          </Typography>
          <Typography variant="h6">{data?.userPartnerSearches?.length || 0}</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          <CustomButton
            variant="text"
            size="small"
            sx={{ fontWeight: '300', fontSize: '13px' }}
            onClick={() => window.open(`${tournament?.platform?.platformLink || process.env.REACT_APP_CLIENT_URL}/tournament/${tournamentId}?tab=search`)}
          >
            view all
          </CustomButton>
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          {/* <TableHead>
            <TableRow>
              <CustomTableCell sx={{ pt: '20px !important' }}>Participants</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Category</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Amount</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Status</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Time</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}></CustomTableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {loading
              ? [...Array(6)].map((_, index) => <SkeletonRow key={index} />)
              : data?.userPartnerSearches?.slice(0, 6)?.map((item, index) => (
                  <TableRow key={index} hover>
                    <CustomTableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <CustomAvatar src={item?.user?.avatar} seed={`${item?.user?.name} ${item?.user?.surname}`} size={18} />
                        <Stack>
                          <Typography color="#0A2540" fontSize="12px">
                            {`${item?.user?.name} ${item?.user?.surname}`}
                          </Typography>
                          <Typography variant="tableFont" color="rgba(10, 37, 64, 0.55)" fontSize="10px">
                            {item?.categoryId?.category?.name || ''}
                          </Typography>
                        </Stack>
                      </Box>
                    </CustomTableCell>

                    <CustomTableCell align="right">
                      <Stack direction="row" gap="5px" justifyContent="flex-end">
                        <CustomButton variant="text" size="small" onClick={() => toast.success('Under development')}>
                          <Info sx={{ fontSize: '16px', color: 'rgba(10, 37, 64, 0.35)' }} />
                        </CustomButton>
                        <CustomButton variant="text" size="small" onClick={() => toast.success('Under development')}>
                          <MoreHoriz sx={{ fontSize: '20px', color: 'rgba(10, 37, 64, 0.35)' }} />
                        </CustomButton>
                      </Stack>
                    </CustomTableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LookingForPartner;
