import { gql } from '@apollo/client';
const GET_GROUP_MATCHES = gql`
  query groupMatches($filter: ListGroupMatchInput) {
    groupMatches(filter: $filter) {
      id
      court {
        name
        id
      }
      round
      date
      score {
        score
      }
      winner {
        id
      }
      time
      group {
        id
        name
        tournamentCategory {
          id
          color
          category {
            name
            type
            isDisplayClub
          }
        }
      }
      entry1 {
        id
        registrationRequest {
          id
          seed
          segment
          assignedSegment
          additionalSegment
          users {
            id
            user {
              id
              email
              avatar
              name
              surname
              nation
              citizenship
              user_club_id {
                id
                clubName
              }
            }
          }
        }
      }
      entry2 {
        id
        registrationRequest {
          id
          seed
          segment
          assignedSegment
          additionalSegment
          users {
            id
            user {
              id
              email
              avatar
              name
              surname
              nation
              citizenship
              user_club_id {
                id
                clubName
              }
            }
          }
        }
      }
    }
  }
`;
export default GET_GROUP_MATCHES;
