import React, { FC } from 'react';
import { Container, ContainerProps } from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';

const PageContainer: FC<ContainerProps> = ({ children, sx, ...props }) => {
  const { settings } = useSettings();

  return (
    <Container maxWidth={settings.compact ? 'xl' : false} {...props} sx={{ ...sx, backgroundColor: '#F4F6F8', minHeight: 'calc(100vh - 65px)', py: '24px' }}>
      {children}
    </Container>
  );
};

export default PageContainer;
