import * as Yup from 'yup';

import { Box, Button, CircularProgress, Divider, Grid, makeStyles, Modal, Paper, TextField, Typography } from '@material-ui/core';
import { CLONE_TOURNAMENT } from 'src/graphql/mutations';
import { Dispatch, FC, Fragment, PropsWithChildren, SetStateAction } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import Scrollbar from 'src/components/Scrollbar';
import toast from 'react-hot-toast';
import useAuth from 'src/hooks/useAuth';
import { TOURNAMENT_BY_ID } from 'src/graphql/queries';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../shared/CustomAccordion';
import { Warning } from '@material-ui/icons';
import CustomDateTimePicker from '../CustomComponents/CustomDateTimePicker';
import CustomFormInput from '../CustomComponents/CustomFormInput';
interface CopyModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    marginTop: 1,
    marginBottom: '8px',
    color: '#0A2540',
  },
}));

const CopyModal: FC<CopyModalProps> = ({ isOpen, setIsOpen }) => {
  const { tournamentId, setTournamentId } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useQuery(TOURNAMENT_BY_ID, {
    skip: !tournamentId,
    variables: { id: tournamentId },
  });
  const [copyTournament] = useMutation(CLONE_TOURNAMENT, {
    refetchQueries: ['allTournamentNames'],
    awaitRefetchQueries: true,
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const FAQS = [
    {
      question: t('wildCardQuestion'),
      icon: <Warning />,
      answer: (
        <p>
          {t('wildCardAnswer')} <b>{t('wildCardAnswer1')}</b> {t('wildCardAnswer2')}
        </p>
      ),
    },
  ];

  return (
    <Fragment>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '560px',
            borderRadius: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 10,
            outline: 'none',
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              title: data?.tournamentDetailById?.title ?? '',
              startDate: data?.tournamentDetailById?.startDate || '',
              endDate: data?.tournamentDetailById?.endDate || '',
              entryDeadline: data?.tournamentDetailById?.entryDeadline || '',
              withdrawlDeadline: data?.tournamentDetailById?.withdrawlDeadline || '',
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required(t('Title is required')),
              startDate: Yup.date().required(t('Start date is required')),
              endDate: Yup.date().min(Yup.ref('startDate'), t('End date cannot be before start date')).required('End date is required'),
              entryDeadline: Yup.date().required(t('Entry deadline is required')),
              withdrawlDeadline: Yup.date().required(t('Withdrawl deadline is required')),
            })}
            onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }): Promise<void> => {
              try {
                const res = await copyTournament({
                  variables: {
                    title: values.title,
                    tournamentId: tournamentId,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    entryDeadline: values.entryDeadline,
                    withdrawlDeadline: values.withdrawlDeadline,
                  },
                });
                setTournamentId(res?.data?.cloneTournament?.id);
                navigate(`${location.pathname}?tournament=${res?.data?.cloneTournament?.id}`);
                handleClose();

                setStatus({ success: true });
                setSubmitting(false);
                toast.success(t('Tournament copied successfully'));
              } catch (err) {
                toast.error(err?.message);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }): JSX.Element => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Paper
                  elevation={12}
                  sx={{
                    width: '100%',
                    maxHeight: '90vh',
                    background: '#FFF',
                    p: 0.5,
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '7px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#aaa',
                      borderRadius: '6px',
                      pl: 3,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#a2a2a2',
                    },
                  }}
                >
                  <Scrollbar>
                    <Box
                      sx={{
                        padding: '20px 20px 0px 20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography color="textPrimary" variant="h2">
                        {t('Copy Tournament')}
                      </Typography>
                      <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
                    </Box>
                    <Box sx={{ padding: '20px 20px 0px 20px' }}>
                      <Divider />
                    </Box>
                    <Box sx={{ padding: '0 20px 20px 20px' }}>
                      <Box sx={{ width: '100%', mt: 2 }}>
                        <Typography color="textSecondary" className={classes.label} variant="body1">
                          {t('Tournament title')}
                        </Typography>
                        <CustomFormInput
                          error={Boolean(touched.title && errors.title)}
                          fullWidth
                          helperText={touched.title && (errors.title as any)}
                          name="title"
                          onChange={handleChange}
                          value={values.title}
                          variant="outlined"
                        />
                        <Grid container spacing={2} mt={0.5}>
                          <Grid item xs={12} md={6}>
                            <Typography color="textSecondary" className={classes.label} variant="body1">
                              {t('Start Date')}
                            </Typography>
                            <CustomDateTimePicker
                              size="medium"
                              value={values.startDate}
                              onChange={(value) => {
                                setFieldValue('startDate', value);
                                // Whenever start date changes also others days have to be automatically updated (end day start day +1day, entry deadline start day -4days, withdrawal deadline start day -2 days).
                                const startDate = moment(value);
                                const endDate = startDate.clone().add(1, 'days').set({ hour: 18, minute: 0 });
                                const entryDeadline = startDate.clone().subtract(4, 'days').set({ hour: 14, minute: 0 });
                                const withdrawlDeadline = startDate.clone().subtract(2, 'days').set({ hour: 14, minute: 0 });
                                setFieldValue('endDate', endDate.format('YYYY-MM-DDTHH:mm'));
                                setFieldValue('entryDeadline', entryDeadline.format('YYYY-MM-DDTHH:mm'));
                                setFieldValue('withdrawlDeadline', withdrawlDeadline.format('YYYY-MM-DDTHH:mm'));
                              }}
                              error={Boolean(touched.startDate && errors.startDate)}
                              helperText={touched.startDate && (errors.startDate as string)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography color="textSecondary" className={classes.label} variant="body1">
                              {t('End Date')}
                            </Typography>
                            <CustomDateTimePicker
                              size="medium"
                              value={values.endDate}
                              onChange={(value) => {
                                setFieldValue('endDate', value);
                              }}
                              error={Boolean(touched.endDate && errors.endDate)}
                              helperText={touched.endDate && (errors.endDate as string)}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={0.5}>
                          <Grid item xs={12} md={6}>
                            <Typography color="textSecondary" className={classes.label} variant="body1">
                              {t('Entry Deadline')}
                            </Typography>
                            <CustomDateTimePicker
                              size="medium"
                              value={values.entryDeadline}
                              onChange={(value) => {
                                setFieldValue('entryDeadline', value);
                              }}
                              error={Boolean(touched.entryDeadline && errors.entryDeadline)}
                              helperText={touched.entryDeadline && (errors.entryDeadline as string)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography color="textSecondary" className={classes.label} variant="body1">
                              {t('Withdrawal Deadline')}
                            </Typography>
                            <CustomDateTimePicker
                              size="medium"
                              value={values.withdrawlDeadline}
                              onChange={(value) => {
                                setFieldValue('withdrawlDeadline', value);
                              }}
                              error={Boolean(touched.withdrawlDeadline && errors.withdrawlDeadline)}
                              helperText={touched.withdrawlDeadline && (errors.withdrawlDeadline as string)}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
                        <Typography variant="h4" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                          {t('FAQ')}
                        </Typography>
                        {FAQS.map((faq, index) => (
                          <CustomAccordion icon={faq.icon} heading={faq.question} children={faq.answer} />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ px: 3 }}>
                      <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        sx={{
                          display: 'inline-flex',
                          height: '50px',
                          padding: '10px 24px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                          // background: "#29ABE2",
                          color: '#FFF',
                          mb: 2,
                        }}
                        type="submit"
                        disabled={isSubmitting}
                        startIcon={isSubmitting && <CircularProgress color="inherit" size={16} />}
                      >
                        {t('Submit')}
                      </Button>
                    </Box>
                  </Scrollbar>
                </Paper>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default CopyModal;
