import React, { Fragment } from 'react';
import { Box, Card, Stack } from '@material-ui/core';
import { Chip, Divider, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomModal from 'src/components/CustomComponents/CustomModal';

const tips = [
  {
    title: 'Setup category settings',
    desc: 'To ensure the entry list functions correctly, make sure to configure number of entries advancing to the main draw, qualification as well as umber of wild cards (WCs) allocated and seeds.',
  },
  {
    title: 'Manage Entries',
    desc: 'Select and manage entries easily by navigating to the Actions dropdown. You can adjust or withdraw entries as needed. \n To import entries, prepare a CSV file in the correct format using the provided template.',
  },
  {
    title: 'Generate Brackets',
    desc: 'Our platform offers full automation for generating groups and draws, with support for multiple formats, including: segmentation for the main draw, qualification, and consolation and live standings for group stages. Manual adjustments are also available.',
  },
  {
    title: 'Manage Results',
    desc: 'Automatically generate the order of play for multiple categories simultaneously. We offer various views, such as: Timeline, Grid and Bird’s-eye view. Easily enter results, and when the tournament is complete, submit the final standings.',
  },
  // {
  //   title: 'Accounting software integrations',
  //   desc: 'Offer your users the ability to seamlessly sync transactions, fees, refunds, and payouts with QuickBooks Online and Xero.',
  // },
];

const Tips = ({ setOpenTips, openTips }) => {
  const truncateText = (text: string, limit: number) => {
    if (text.length <= limit) return text;
    return text.slice(0, limit) + '...';
  };

  // In component
  const CHAR_LIMIT = 150;

  return (
    <Box sx={{ p: '15px', borderRadius: '8px', border: 1, borderColor: '#edf1f6', position: 'relative', background: 'white' }}>
      <Stack
        flexDirection="row"
        overflow="auto"
        gap="25px"
        width="100%"
        sx={{
          maxWidth: '100%',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: '4px',
          },
        }}
      >
        {tips.map((item, index) => (
          <Fragment key={index}>
            <Box
              sx={{
                minWidth: '350px',
                maxWidth: '441px',
              }}
            >
              <CustomChip size="xs" variant="text" sx={{ backgroundColor: '#0a25401a', marginBottom: '15px', width: '55px', color: 'rgba(10, 37, 64, 0.55)', fontSize: '12px' }}>
                Tip #{index + 1}
              </CustomChip>
              <Typography variant="h6" sx={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>
                {item.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#0a25408c', marginBottom: '10px' }}>
                {truncateText(item.desc, CHAR_LIMIT)}
              </Typography>
              <CustomModal
                triggerEl={
                  <CustomButton variant="text" size="small" sx={{ color: '#ff5733', marginBottom: '20px', fontWeight: '400', p: 0 }}>
                    Read more
                  </CustomButton>
                }
                title={item.title}
              >
                <Box
                  sx={{
                    maxWidth: '700px',
                    overflow: 'auto',
                    '& p': {
                      margin: '0px',
                      padding: '0px',
                      lineHeight: '22.68px',
                    },
                    '& li': {
                      margin: '0px',
                      padding: '0px',
                      lineHeight: '22.68px',
                    },
                    'h1, h2, h3': {
                      margin: '0px',
                    },
                  }}
                >
                  {item.desc}
                </Box>
              </CustomModal>
            </Box>
            {index < tips.length - 1 && <Divider orientation="vertical" flexItem sx={{ borderColor: '#EDF1F6' }} />}
          </Fragment>
        ))}
      </Stack>
      <IconButton sx={{ position: 'absolute', top: 5, right: 15 }} aria-label="close" onClick={() => setOpenTips(!openTips)}>
        <Close sx={{ fontSize: '16px', color: '#0A254040' }} />
      </IconButton>
    </Box>
  );
};

export default Tips;
