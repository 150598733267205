import { TournamentPreferences } from 'src/types/tournamentPreferences';
import useAuth from './useAuth';
import { useQuery } from '@apollo/client';
import { TOURNAMENT_PREFERENCES } from 'src/graphql/queries';
import { useMemo } from 'react';

export const useTournamentPreferences = (): TournamentPreferences => {
  const { tournamentId, leagueId } = useAuth();
  const { data, loading } = useQuery(TOURNAMENT_PREFERENCES, {
    skip: !leagueId,
    variables: { id: leagueId },
  });

  const preferences = data?.league?.sports || {};
  // console.log('preferences', data, leagueId);

  return {
    enableCourts: preferences.enableCourts || false,
    enableStages: preferences.enableStages || false,
    isEnableRounds: preferences.isEnableRounds || false,
    displayDraws: preferences.displayDraws || false,
    displayGroups: preferences.displayGroups || false,
    displayLiveStandings: preferences.displayLiveStandings || false,
    displayOrderPlay: preferences.displayOrderPlay || false,
    displayPendingResults: preferences.displayPendingResults || false,
    displayTournamentResults: preferences.displayTournamentResults || false,
    displayListResults: preferences.displayListResults || false,
    isEnablePairings: preferences.isEnablePairings || false,
    isEnablePairingStandings: preferences.isEnablePairingStandings || false,
    isDisplaySeeds: preferences.isDisplaySeeds || false,
    isDisplaySegments: preferences.isDisplaySegments || false,
    isLoadingPreferences: !leagueId || loading,
  };
};
