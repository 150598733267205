import { gql } from '@apollo/client';

const MASTER_UPDATE_REGISTRATION_SEGMENT = gql`
  mutation masterUpdateRegistrationSegment($tournamentId: Float!, $categoryId: Float!, $segment: [RegistrationSegment!]) {
    masterUpdateRegistrationSegment(tournamentId: $tournamentId, categoryId: $categoryId, segment: $segment) {
      id
    }
  }
`;

export default MASTER_UPDATE_REGISTRATION_SEGMENT;
