import { gql } from 'src/__generated__/gql';

const ENTRIES = gql(`
  query tournamentRegistrations($filter: ListTournamentRegistrationInput, $orderBy: String) {
    tournamentRegistrations(filter: $filter, orderBy: $orderBy) {
      id
      type
      status
      rankType
      createdAt
      updatedAt
      assignedSegment
      additionalSegment
      seed
      amount
      segment
      paymentMethod
      paymentStatus
      withdrawReason
      additionalQuestions {
        id
        question {
          id
          question
          fieldOptions
        }
        answer
        file
        fieldType
        amount
        user {
          id
          email
          name
          surname
        }
      }
      categoryId {
        mdwc
        qwc
        qs
        category {
          id
          name
        }
      }
      team {
        id
        title
        country
        image
      }
      users {
        id
        fideTitle
        rank
        blitzElo
        standardElo
        rapidElo
        duprRating
        user {
          duprId
          rank
          id
          name
          phone
          surname
          avatar
          nation
          citizenship
          email
          gender
          dob
          duprId
          city
          user_club_id {
            id
            clubName
          }
        }
      }
    }
  }
`);

export default ENTRIES;
