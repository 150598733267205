import { makeStyles, TextField } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/lab';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import CustomFormInput from './CustomFormInput';

type Props = {
  error?: boolean;
  helperText?: string;
  className?: string;
  renderInput?: (props: any) => React.ReactElement;
  value: Moment | string | Date | null;
  onChange: (date: Moment | string | Date | null) => void;
  disabled?: boolean;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles((theme) => ({
  dateTimePicker: {
    '& .MuiTabs-root': {
      background: 'white !important',
    },
    '& div[class*="PrivatePickersCalendar-root"]': {
      minHeight: '200px',
    },
  },
}));

const CustomDateTimePicker: FC<Props> = ({ error, helperText, value, onChange, className, disabled, size = 'medium', ...props }) => {
  const classes = useStyles();

  const handleChange = (newValue: Moment, keyboardInputValue?: string) => {
    if (keyboardInputValue) {
      const parsedDate = moment.utc(keyboardInputValue, 'DD/MM/yyyy, HH:mm');
      onChange(parsedDate.isValid() ? parsedDate : null);
    } else if (newValue) {
      if (moment.isMoment(newValue)) {
        onChange(newValue.utc().format());
      } else {
        onChange(moment(newValue).utc().format());
      }
    } else {
      onChange(null);
    }
  };

  return (
    <DateTimePicker
      disabled={disabled}
      mask="__/__/____, __:__"
      disableHighlightToday
      inputFormat="DD/MM/yyyy, HH:mm"
      className={`${classes.dateTimePicker} ${className}`}
      ampm={false}
      onChange={handleChange}
      value={moment.utc(value)}
      openPickerIcon={<CalendarToday sx={{ fontSize: '12px' }} />}
      renderInput={(props) => <CustomFormInput {...props} error={error} helperText={helperText} fullWidth size={size} />}
      {...props}
    />
  );
};

export default CustomDateTimePicker;
