import { gql } from 'src/__generated__/gql';

const REMOVE_TOURNAMENT_TEAM_MEMBER = gql(`
  mutation removeTournamentTeamMember($id: Int!) {
    removeTournamentTeamMember( id: $id) {
      team {
        title
      }
    }
  }
`);

export default REMOVE_TOURNAMENT_TEAM_MEMBER;
