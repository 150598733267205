import { Box, Typography } from '@material-ui/core';
import { Fragment, useState } from 'react';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import GetFlag from 'src/components/shared/GetFlag';
import { useResourceTitle } from 'src/hooks/useResourceTitle';
import { getColorByRating, getFideTitle } from 'src/utils/helper-functions';
import UserDetailModal from './UserDetailModal';
import { DUPRBox } from './DUPRBox';

const UserName = ({ user, shouldDisplayDupr = false }) => {
  const [openModal, setOpenModal] = useState(false);
  const resourceTitle = useResourceTitle();

  return (
    <Fragment>
      <Typography
        variant="body1"
        sx={{
          width: 'max-content',
          color: '#193660',
          fontWeight: 500,
          cursor: 'pointer',
        }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': { color: '#29ABE2' },
              maxWidth: '300px',
            }}
          >
            <GetFlag
              country={user?.user?.citizenship ? user?.user?.citizenship : user?.user?.nation}
              style={{
                width: '1.2em',
                height: '1.2em',
                marginRight: '10px',
              }}
            />
            <CustomAvatar src={user?.user?.avatar} size={26} seed={user?.user?.name + ' ' + user?.user?.surname} />

            {/* <Avatar
              sx={{
                width: '30px',
                height: '30px',
                mx: 1,
              }}
              src={user?.user?.avatar}
            /> */}
            <Typography
              variant="tableFont"
              sx={{
                '&:hover': { color: (theme) => theme.palette.primary.main },
                mx: 1,
                textTransform: 'uppercase',
              }}
            >
              {user?.user?.name ? user?.user?.name : 'Not Added'} {user?.user?.surname ? user?.user?.surname : ''}{' '}
            </Typography>

            {resourceTitle === 'Round' && (
              <Box
                sx={{
                  padding: '3px 5px',
                  background: getFideTitle(user?.fideTitle)?.color,
                  color: '#FFFF',
                  borderRadius: '5px',
                  marginRight: '10px',
                  width: 'max-content',
                  marginLeft: '10px',
                }}
              >
                {getFideTitle(user?.fideTitle)?.abbreviation}
              </Box>
            )}
            {shouldDisplayDupr && user?.duprRating && <DUPRBox rating={user?.duprRating} color={getColorByRating(user?.duprRating)} />}
          </Box>
        </Box>
      </Typography>
      <UserDetailModal user={user?.user} openModal={openModal} setModal={setOpenModal} />
    </Fragment>
  );
};

export default UserName;
