import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';
import { Divider, List, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Tournament } from 'src/__generated__/graphql';
import { COUNT, FIND_USER_TOURNAMENT_ROLE } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';
import { useResourceTitle } from 'src/hooks/useResourceTitle';
import { useTournamentPreferences } from 'src/hooks/useTournamentPreferences';
import SidebarItem from './SidebarItem';
import useQueryParams from 'src/hooks/useQueryParams';
import { USER_ROLES } from 'src/constants';

const SidebarMenu: React.FC<{
  selectedTournament: Tournament;
  openSideBarState: boolean;
  onMobileClose: () => void;
}> = ({ selectedTournament, openSideBarState, onMobileClose }) => {
  const { tournamentId, tournament, user } = useAuth();
  const resourceTitle = useResourceTitle();
  const { getParam } = useQueryParams();
  const { data } = useQuery(FIND_USER_TOURNAMENT_ROLE, {
    skip: !tournamentId,
    variables: {
      tournamentId: tournamentId,
    },
  });
  const tourIdParam = getParam('tournament') ? `?tournament=${getParam('tournament')}` : '';
  const { t } = useTranslation();
  const {
    enableCourts,
    enableStages,
    isEnableRounds,
    displayDraws,
    displayGroups,
    displayLiveStandings,
    displayOrderPlay,
    displayTournamentResults,
    displayListResults,
    displayPendingResults,
    isEnablePairingStandings,
    isEnablePairings,
    isLoadingPreferences,
  } = useTournamentPreferences();

  const { data: countData } = useQuery(COUNT, {
    variables: {
      tournament: tournamentId,
    },
  });
  const sidebarItems = [
    [
      {
        label: 'Tournament Overview',
        icon: '/images/dashboard-icon.svg',
        link: '/dashboard',
      },
    ],
    [
      {
        label: 'Tournament Settings',
        icon: '/images/general-data.svg',
        link: `/dashboard/general-settings${tourIdParam}`,
      },
      // {
      //   label: 'Category Settings',
      //   icon: '/images/category.svg',
      //   link: '/dashboard/configure-settings',
      // },
      // {
      //   label: `${resourceTitle} ${t('Settings')}`,
      //   icon: '/images/court.svg',
      //   link: '/dashboard/resource-settings',
      //   hidden: resourceTitle === 'Stage' ? !enableStages : !enableCourts,
      // },
    ],
    [
      {
        label: 'Requests',
        icon: '/images/general-data.svg',
        link: '/dashboard/requests',
        count: countData?.registrationCount?.pendingRequests,
      },
      {
        label: 'Entries',
        icon: '/images/entries.svg',
        link: '/dashboard/entries',
        count: countData?.registrationCount?.confirmedRequests,
      },
      {
        label: 'Withdrawals',
        icon: '/images/withdraw.svg',
        link: '/dashboard/withdrawals',
        count: countData?.registrationCount?.withdrawnRequests,
      },
      {
        label: 'Participants',
        icon: '/images/participants.svg',
        link: '/dashboard/participants',
        count: countData?.registrationCount?.participantsCount,
      },
      {
        label: 'Transactions',
        icon: '/images/transaction.svg',
        link: '/dashboard/transactions',
        hidden: data?.findUserTournamentRole?.role && data?.findUserTournamentRole?.role == 'referee',
        count: countData?.registrationCount?.transactionsCount,
      },
    ],
    [
      {
        label: 'Pairings',
        icon: '/images/draws.svg',
        link: '/dashboard/pairings',
        hidden: !isEnablePairings,
        loading: isLoadingPreferences,
      },
      {
        label: 'Standings',
        icon: '/images/chess-king.svg',
        link: '/dashboard/pairings-standings',
        hidden: !isEnablePairingStandings,
        loading: isLoadingPreferences,
      },
      {
        label: 'Custom Metrics',
        icon: '/images/fishicon.svg',
        link: '/dashboard/custom-metrics',
        hidden: !displayListResults,
        loading: isLoadingPreferences,
      },
      {
        label: 'List & Results',
        icon: '/images/result.svg',
        link: '/dashboard/list-results',
        hidden: !displayListResults,
        loading: isLoadingPreferences,
      },
      {
        label: 'Groups',
        icon: '/images/groups.svg',
        link: '/dashboard/groups',
        hidden: !displayGroups,
        loading: isLoadingPreferences,
      },
      {
        label: 'Draws',
        icon: '/images/draws.svg',
        link: '/dashboard/draws',
        hidden: !displayDraws,
        loading: isLoadingPreferences,
      },
      {
        label: 'Order Of Play',
        icon: '/images/order-op-play.svg',
        link: '/dashboard/order-of-play',
        hidden: !displayOrderPlay,
        loading: isLoadingPreferences,
      },
      {
        label: 'Live Standings',
        icon: '/images/live-standings-2.svg',
        link: '/dashboard/live-standings',
        hidden: !displayLiveStandings,
        loading: isLoadingPreferences,
      },
      {
        label: 'Final Standings',
        icon: '/images/final-standings.svg',
        link: '/dashboard/tournament-results',
        hidden: !displayTournamentResults,
        loading: isLoadingPreferences,
      },
    ],
    [
      {
        label: 'Documents',
        icon: '/images/documents-2.svg',
        link: '/dashboard/documents',
      },
      {
        label: 'Partners',
        icon: '/images/partners-2.svg',
        link: '/dashboard/partners',
      },
      {
        label: 'Gallery',
        icon: '/images/gallery-2.svg',
        link: `${selectedTournament?.platform?.platformLink || process.env.REACT_APP_CLIENT_URL}/gallery`,
      },
    ],
  ];
  return selectedTournament ? (
    <List>
      {sidebarItems.map((items, index) => (
        <Fragment key={index}>
          {index !== 0 && <Divider sx={{ borderColor: 'rgba(237, 241, 246, 1)' }} />}
          {items.map(
            (item) =>
              !item.hidden && (
                <SidebarItem
                  key={item.label}
                  icon={item.icon}
                  label={t(item.label)}
                  open={openSideBarState}
                  count={item.count}
                  link={item.link}
                  isLoading={item.loading}
                  onMobileClose={onMobileClose}
                />
              ),
          )}
        </Fragment>
      ))}
    </List>
  ) : (
    <Typography
      variant="body1"
      sx={{
        color: '#86909F',
        fontWeight: 500,
        my: 20,
        textAlign: 'center',
        fontSize: '12px',
      }}
    >
      There is no
      <br />
      tournaments in this league
    </Typography>
  );
};

export default SidebarMenu;
