import { Autocomplete, Box, Skeleton, Stack, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router';
import { League, Tournament } from 'src/__generated__/graphql';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import CustomPopover from 'src/components/CustomComponents/CustomPopover';
import LoadableImage from 'src/components/CustomComponents/LoadableImage';
import useAuth from 'src/hooks/useAuth';
import useQueryParams from 'src/hooks/useQueryParams';
import ChevronDown from 'src/icons/ChevronDown';

const LeagueSelection: React.FC<{
  leagueLoading: boolean;
  loading: boolean;
  openSideBarState: boolean;
  league: League;
  leagues: League[];
  setLeague: Dispatch<SetStateAction<League>>;
  setSelectedTournament: Dispatch<SetStateAction<Tournament>>;
  setPage: Dispatch<SetStateAction<number>>;
  setSearchTitle: Dispatch<SetStateAction<string>>;
  setTournaments: Dispatch<SetStateAction<Tournament[]>>;
  setHasMore: Dispatch<SetStateAction<boolean>>;
}> = ({ leagueLoading, loading, openSideBarState, league, leagues, setLeague, setSelectedTournament, setPage, setSearchTitle, setTournaments, setHasMore }) => {
  const navigate = useNavigate();
  const { setTournamentId } = useAuth();
  const { setParam } = useQueryParams();

  return (
    <Stack sx={{ backgroundColor: '#FAFAFA', p: '15px', alignItems: 'center' }}>
      {/* <ChevronDown sx={{ color: '#86909F', fontSize: '20px' }} /> */}
      {leagueLoading ? (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }}>
          <Skeleton variant="rectangular" width={45} height={45} sx={{ borderRadius: '4px' }} />
          {openSideBarState && (
            <Stack spacing={0.5} sx={{ flex: 1 }}>
              <Skeleton variant="text" width="80%" height={24} />
              <Skeleton variant="text" width="40%" height={20} />
            </Stack>
          )}
        </Stack>
      ) : (
        <CustomPopover
          modalOnMobile
          matchTriggerWidth={openSideBarState}
          popoverProps={{
            sx: {
              '& .MuiPopover-paper': {
                maxHeight: 'none',
                height: '250px !important',
                overflow: 'visible',
                display: 'block',
                minWidth: openSideBarState ? 'auto' : '260px',
              },
              '& .MuiAutocomplete-paper': {
                maxHeight: 'none',
                overflow: 'visible',
                display: 'block',
                border: 'none !important',
                boxShadow: 'none !important',
                minWidth: openSideBarState ? 'auto' : '240px',
              },
              '& .MuiAutocomplete-listbox': {
                maxHeight: '190px !important',
              },
              '& .MuiAutocomplete-popper': {
                transform: 'translate(11px, 50px) !important',
                position: 'absolute',
                inset: '0px auto auto 0px',
                width: '179px',
                margin: '0px',
              },
            },
          }}
          triggerEl={
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                cursor: 'pointer',
              }}
            >
              <LoadableImage
                sx={{
                  minWidth: '45px',
                  minHeight: '45px',
                  width: '45px',
                  height: '45px',
                  backgroundColor: '#ffff',
                  objectFit: 'cover',
                }}
                imageSrc={league?.logo || ''}
                fallbackImageSrc={league?.platform?.lightLogo || ''}
                alt="league"
                loader={<Skeleton variant="rectangular" width={45} height={45} sx={{ borderRadius: '4px' }} />}
              />
              {openSideBarState && (
                <>
                  <Stack spacing={0.5}>
                    <Typography
                      variant="body1"
                      sx={{ color: '#0A2540', fontWeight: 600, fontFamily: 'Eudoxus Sans, sans-serif', width: '120px', overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}
                    >
                      {league?.title || ''}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#0A25408C', fontWeight: 500, fontFamily: 'Eudoxus Sans, sans-serif' }}>
                      {league?.country || ''}
                    </Typography>
                  </Stack>
                  <ChevronDown sx={{ color: '#86909F', fontSize: '20px' }} />
                </>
              )}
            </Stack>
          }
        >
          {(handleClose) => (
            <Box>
              <Autocomplete
                options={leagues || []}
                isOptionEqualToValue={(option: League, value: League) => option?.id === value?.id}
                value={league || null}
                getOptionLabel={(option) => option?.title}
                openOnFocus
                disablePortal
                open={true}
                popupIcon={null}
                clearIcon={null}
                disableClearable
                loading={leagueLoading}
                noOptionsText="No league found"
                renderOption={(props, option) => (
                  <li {...props} key={option?.id}>
                    {option?.title}
                  </li>
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#FAFAFA',
                    borderColor: '#EDF1F6',
                    color: '#0A2540',
                  },
                }}
                onChange={async (e, value: League) => {
                  setLeague(value);
                  setSelectedTournament(null);
                  setPage(0);
                  setSearchTitle('');
                  setTournaments([]);
                  setHasMore(true);
                  handleClose();
                  setParam({ tournament: '' }); // Clear tournament param
                  await setTournamentId(null, value?.id);
                }}
                renderInput={(params) => <CustomFormInput {...params} variant="outlined" size="medium" placeholder="Search" autoFocus />}
              />
            </Box>
          )}
        </CustomPopover>
      )}
      <CustomButton
        size="xs"
        variant="text"
        onClick={() => {
          navigate(`/dashboard/all-tournaments/new?leagueId=${league?.id}`);
          setTournamentId(null);
        }}
        sx={{
          gap: '4px',
          mt: '12px',
          textAlign: 'center',
          fontSize: '12px',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <Add sx={{ fontSize: '16px' }} />
        {openSideBarState && 'Create tournament'}
      </CustomButton>
    </Stack>
  );
};

export default LeagueSelection;
