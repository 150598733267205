import { gql } from 'src/__generated__/gql';

const GROUP_ENTRIES = gql(`
  query groupEntries($groupIds: [Int!], $filter: ListGroupEntryInput) {
    groupEntries(groupIds: $groupIds, filter: $filter) {
      id
      group {
        id
        name
      }
      place
      registrationRequest {
        team {
              id
              title
              image
            }
            id
            segment
            assignedSegment
            additionalSegment
            seed
            users {
              id
              user {
                id
                email
                name
                surname
                avatar
                nation
              }
            }
      }
    }
  }
`);
export default GROUP_ENTRIES;
