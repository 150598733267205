import { useQuery } from '@apollo/client';
import { Box, Skeleton, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { ChevronRight, Info } from '@material-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import CustomTableCell from 'src/components/CustomComponents/CustomTableCell';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import GetFlag from 'src/components/shared/GetFlag';
import TRANSACTIONS from 'src/graphql/queries/allTransactions';
import useAuth from 'src/hooks/useAuth';
import { getCurrencySymbol } from 'src/utils/helper-functions';

const data = [
  {
    flag: 'Latvia',
    avatar: 'https://via.placeholder.com/18',
    name: 'Susan Clark',
    category: 'Men U12',
    tournamentCategory: [
      {
        category: {
          name: 'Girls U12',
        },
      },
      {
        category: {
          name: 'Boys U12',
        },
      },
    ],
    amount: '€249.98',
    status: 'Confirmed',
    statusColor: 'success.main',
    date: '29 Jun 2020',
    time: '21:37',
  },
  {
    flag: 'Pakistan',
    avatar: 'https://via.placeholder.com/18',
    name: 'Susan Clark',
    category: 'Men U12',
    tournamentCategory: [
      {
        category: {
          name: 'Girls U12',
        },
      },
      {
        category: {
          name: 'Boys U12',
        },
      },
    ],
    amount: '€249.98',
    status: 'Pending payment',
    statusColor: 'info.main',
    date: '29 Jun 2020',
    time: '21:37',
  },
  {
    flag: 'India',
    avatar: 'https://via.placeholder.com/18',
    name: 'Kaminskiy Robert',
    category: 'Men U12',
    tournamentCategory: [
      {
        category: {
          name: 'Girls U12',
        },
      },
      {
        category: {
          name: 'Boys U12',
        },
      },
    ],
    amount: '€249.98',
    status: 'Partial payment',
    statusColor: 'info.main',
    date: '29 Jun 2020',
    time: '21:37',
  },
  {
    flag: 'Latvia',
    avatar: 'https://via.placeholder.com/18',
    name: 'Susan Clark',
    category: 'Men U12',
    tournamentCategory: [
      {
        category: {
          name: 'Girls U12',
        },
      },
      {
        category: {
          name: 'Boys U12',
        },
      },
    ],
    amount: '€249.98',
    status: 'Requested',
    statusColor: 'warning.main',
    date: '29 Jun 2020',
    time: '21:37',
  },
  {
    flag: 'Pakistan',
    avatar: 'https://via.placeholder.com/18',
    name: 'Susan Clark',
    category: 'Men U12',
    tournamentCategory: [
      {
        category: {
          name: 'Girls U12',
        },
      },
      {
        category: {
          name: 'Boys U12',
        },
      },
    ],
    amount: '€249.98',
    status: 'Withdrawn',
    statusColor: 'error.main',
    date: '29 Jun 2020',
    time: '21:37',
  },
];

const SkeletonRow = () => (
  <TableRow>
    <CustomTableCell>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Skeleton variant="circular" width={18} height={18} />
        <Skeleton variant="text" width={100} height={20} />
      </Box>
    </CustomTableCell>
    <CustomTableCell>
      <Skeleton variant="text" width={60} height={20} />
    </CustomTableCell>
    <CustomTableCell>
      <Stack direction="row" gap={1}>
        <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: 16 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Skeleton variant="text" width={50} height={20} />
          <Skeleton variant="circular" width={24} height={24} />
        </Box>
      </Stack>
    </CustomTableCell>
    <CustomTableCell>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Skeleton variant="circular" width={8} height={8} />
        <Skeleton variant="text" width={80} height={20} />
      </Box>
    </CustomTableCell>
    <CustomTableCell>
      <Skeleton variant="text" width={80} height={20} />
      <Skeleton variant="text" width={40} height={15} />
    </CustomTableCell>
  </TableRow>
);

const TransactionsList = () => {
  const { t } = useTranslation();
  const { tournamentId, tournament } = useAuth();
  const navigate = useNavigate();

  const { data: transactionsData, loading } = useQuery(TRANSACTIONS, {
    skip: !tournamentId,
    variables: {
      tournament: tournamentId,
    },
  });

  return (
    <Box
      sx={{
        p: '15px',
        borderRadius: '8px',
        border: 1,
        borderColor: '#edf1f6',
        position: 'relative',
        background: 'white',
        height: '100%',
      }}
    >
      <Box display="flex" justifyContent="space-between" pb={1} alignItems="center">
        <Typography
          variant="h6"
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            fontFamily: 'Inter, sans-serif',
            color: '#0A2540',
          }}
        >
          Latest Transactions
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <CustomButton variant="text" size="small" sx={{ fontWeight: '300', fontSize: '13px' }} onClick={() => navigate('/dashboard/transactions')}>
            view all
          </CustomButton>
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell sx={{ pt: '20px !important' }}>Player</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Amount paid</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Categories</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Status</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Time</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? [...Array(6)].map((_, index) => <SkeletonRow key={index} />)
              : transactionsData?.allTransactionHistory?.slice(0, 6)?.map((item, index) => (
                  <TableRow key={index} hover sx={{ backgroundColor: index % 2 ? '#FBFBFB' : 'white' }}>
                    <CustomTableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {/* <GetFlag
                      country={item.flag}
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      imgStyle={{
                        borderRadius: '5px',
                      }}
                    /> */}
                        <CustomAvatar src={item?.user?.avatar} seed={`${item?.user?.name} ${item?.user?.surname}`} size={18} />
                        <Typography variant="font15" color="#0A2540">
                          {`${item?.user?.name} ${item?.user?.surname}` || ''}
                        </Typography>
                      </Box>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography color="#0A2540" fontSize="12px">
                        {item?.amount ? `${item?.amount?.toFixed(2)}${getCurrencySymbol(tournament?.currency)}` : '-'}
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Stack direction="row" gap="5px">
                        -
                        {/* <CustomChip
                          size="small"
                          variant="secondary"
                          sx={{
                            color: 'rgba(10, 37, 64, 0.55)',
                            borderColor: '#EDF1F6',
                            width: 'min-content',
                            fontSize: '12px',
                          }}
                        >
                          {item.tournamentCategory[0]?.category?.name}
                        </CustomChip>
                        <CustomTooltip
                          title={
                            <Box sx={{ maxHeight: { xs: 'none', sm: '250px' }, overflow: 'auto' }}>
                              <Stack gap="8px">
                                {item?.tournamentCategory?.slice(1).map(
                                  (cat, i) =>
                                    cat?.category?.name && (
                                      <Typography
                                        key={i}
                                        variant="font16"
                                        color="#0A2540"
                                        sx={{
                                          paddingInline: '6px',
                                          '&:not(:last-child)': {
                                            borderBottom: '1px solid rgba(0,0,0,0.1)',
                                            paddingBottom: '4px',
                                          },
                                        }}
                                      >
                                        {cat?.category?.name}
                                      </Typography>
                                    ),
                                )}
                              </Stack>
                            </Box>
                          }
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                            <Typography variant="tableFont" color="rgba(10, 37, 64, 0.55)">
                              and {item?.tournamentCategory?.length - 1} more
                            </Typography>
                            <CustomChip shape="circle" size="small" variant="secondary" sx={{ pointerEvents: 'none' }}>
                              <ChevronRight sx={{ fontSize: '16px' }} />
                            </CustomChip>
                          </Box>
                        </CustomTooltip> */}
                      </Stack>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            bgcolor: item.statusColor,
                          }}
                        />
                        <Typography color="#0A2540" fontSize="12px">
                          {/* {item.status} */}-
                        </Typography>
                      </Box>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography color="#0A2540" fontSize="12px">
                        {moment(item?.createdAt).format('DD MMM YYYY')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" fontSize="10px">
                        {moment(item?.createdAt).format('HH:mm')}
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TransactionsList;
