import { gql } from 'src/__generated__/gql';

const CREATE_DRAW_FROM_LIVE_STANDINGS = gql(`
  mutation createDrawFromGroupsWithLiveStandings($tournament: Int!, $tournamentCategory: Int, $size: Int, $numberOfEntries: Int, $title: String, $entriesPosition: String, $finalDraws: Boolean, $groupIds: [Int!]!, $genDraw: String, $segment: String, $thirdPlaceDraw: Boolean, $drawId: Int, $type: String) {
    createDrawFromGroupsWithLiveStandings(
      createDrawInput: {
        tournament: $tournament
        tournamentCategory: $tournamentCategory
        size: $size
        numberOfEntries: $numberOfEntries
        title: $title
        entriesPosition: $entriesPosition
        finalDraws: $finalDraws
        genDraw: $genDraw
        segment: $segment
        thirdPlaceDraw: $thirdPlaceDraw
        type: $type
      }
      groupIds: $groupIds
      drawId: $drawId
    ) {
      id
      title
    }
  }
`);

export default CREATE_DRAW_FROM_LIVE_STANDINGS;
