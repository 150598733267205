import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Chip, Divider, Paper, Skeleton, Stack, Typography } from '@material-ui/core';
import { blue, green } from '@material-ui/core/colors';
import { cloneDeep } from 'lodash';
import { ReactSVG } from 'react-svg';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import { ALL_TOURNAMENT_CATEGORIES, ENTRIES, TOURNAMENT_TEAM_MEMBERS } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';
import ExcelJS from 'exceljs';
import { getAnswer } from 'src/utils/helper-functions';
import { Participants } from 'src/types';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import { useMemo } from 'react';
import moment from 'moment';

const ParticipantsListSkeleton = () => (
  <Box display="flex" gap={2} height="100%">
    <Box
      sx={{
        width: '100%',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        border: 1,
        borderRadius: '8px',
        borderColor: '#edf1f6',
        background: 'white',
        height: '100%',
      }}
    >
      <Box display="flex" justifyContent="space-between" borderBottom={1} borderColor="#EDF1F6" pb={1}>
        <Box>
          <Skeleton variant="text" width={80} height={20} />
          <Stack direction="row" gap="15px" alignItems="center">
            <Skeleton variant="text" width={40} height={32} />
            <Skeleton variant="rectangular" width={80} height={28} sx={{ borderRadius: 1 }} />
          </Stack>
        </Box>
        <Skeleton variant="text" width={60} height={20} />
      </Box>

      <Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={5} height={5} />
            <Skeleton variant="text" width={40} height={20} />
          </Box>
          <Skeleton variant="text" width={30} height={20} />
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={5} height={5} />
            <Skeleton variant="text" width={40} height={20} />
          </Box>
          <Skeleton variant="text" width={30} height={20} />
        </Box>
        <Box display="flex" gap={0.5} mt={1} mb={2}>
          <Skeleton variant="rectangular" width="45%" height={5} sx={{ borderRadius: 1000 }} />
          <Skeleton variant="rectangular" width="55%" height={5} sx={{ borderRadius: 1000 }} />
        </Box>
      </Box>

      <Divider sx={{ borderColor: '#EDF1F6' }} />

      <Box>
        {[1, 2, 3].map((item) => (
          <Box key={item} display="flex" justifyContent="space-between" mb={1}>
            <Skeleton variant="text" width={60} height={20} />
            <Skeleton variant="text" width={40} height={20} />
          </Box>
        ))}
      </Box>

      <Divider sx={{ borderColor: '#EDF1F6' }} />

      <Box>
        <Box display="flex" justifyContent="space-between">
          <Skeleton variant="text" width={80} height={24} />
          <Skeleton variant="text" width={60} height={20} />
        </Box>
        <Box display="flex" flexDirection="column" gap={1} mt={2}>
          {[1, 2, 3].map((item) => (
            <Box key={item} display="flex" justifyContent="space-between" gap="15px">
              <Skeleton variant="rectangular" width="80%" height={28} sx={{ borderRadius: 16 }} />
              <Skeleton variant="text" width={30} height={20} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  </Box>
);

const ProgressBar = ({ color, width }) => {
  if (width === '0%') {
    return null;
  }
  return (
    <Box sx={{ position: 'relative', width: width }}>
      <Box
        sx={{
          height: 5,
          borderRadius: 1000,
          backgroundColor: color,
          width: '100%',
        }}
      />
      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          bottom: -25,
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          width: '100%',
          color: 'rgba(10, 37, 64, 0.55)',
        }}
      >
        {width}
      </Typography>
    </Box>
  );
};

const ParticipantsList = () => {
  const { tournament, tournamentId } = useAuth();
  const isPickleballTournamnet = tournament?.league?.league?.sports?.title?.toLowerCase() === 'pickleball';
  const isDuprRatingEnabled = tournament?.isDuprRatingEnabled;
  const navigate = useNavigate();

  const { loading: isLoading, data } = useQuery(TOURNAMENT_TEAM_MEMBERS, {
    skip: !tournamentId,
    variables: {
      filter: {
        tournament: tournamentId,
      },
      status: 'confirm',
      // isUnique: true,
    },
  });

  const { loading: categoryLoading, data: categories } = useQuery(ALL_TOURNAMENT_CATEGORIES, {
    variables: {
      filter: {
        tournament: tournamentId,
        registrationStatus: 'confirm',
      },
    },
  });

  const tournamentTeamMembers = data?.tournamentTeamMembers || [];
  const groupedByEmail = tournamentTeamMembers?.reduce((acc, member) => {
    const email = member?.user?.email;
    if (email) {
      if (!acc[email]) {
        acc[email] = {
          ...member,
          categories: [],
        };
      }
      acc[email]?.categories?.push(member?.categoryId);
    }
    return acc;
  }, {});

  const uniqueByEmail: Participants = Object?.values(groupedByEmail);

  const downloadAllExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');
      let headers;
      headers = ['Name', 'Surname', 'Email', 'Phone number', 'Gender', 'Country', ...(isPickleballTournamnet ? ['DUPR ID', 'DUPR Rating'] : []), 'Categories'];

      worksheet.addRow(headers);

      uniqueByEmail?.forEach((participant, index) => {
        const row = [
          participant?.user?.name || 'Not Added',
          participant?.user?.surname || 'Not Added',
          participant?.user?.email || 'Not Added',
          participant?.user?.phone || 'Not Added',
          participant?.user?.gender || 'Not Added',
          participant?.user?.nation || 'Not Added',
          ...(isPickleballTournamnet ? [participant?.user?.duprId || 'Not Added'] : []),
          ...(isDuprRatingEnabled ? [participant?.duprRating || 'Not Added'] : []),
          participant?.categories?.map((category) => category?.category?.name).join(', ') || 'Not Added',
        ];

        const excelRow = worksheet.addRow(row);

        excelRow.eachCell((cell) => {
          cell.alignment = { horizontal: 'left' };
        });
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'participant_list.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const participantStats = useMemo(() => {
    const total = uniqueByEmail.length || 1;
    const men = uniqueByEmail.filter((p) => p.user?.gender?.toLowerCase() === 'male').length;
    const women = uniqueByEmail.filter((p) => p.user?.gender?.toLowerCase() === 'female').length;

    // Ensure percentages sum to 100%
    const remainingPercent = 100 - (Math.floor((men / total) * 100) + Math.floor((women / total) * 100));
    const menPercent = Math.floor((men / total) * 100);
    const womenPercent = Math.floor((women / total) * 100) + remainingPercent;

    // Calculate valid ages with proper filtering
    const validAges = uniqueByEmail
      .filter((p) => p.user?.dob && moment(p.user.dob).isValid())
      .map((p) => moment().diff(moment(p.user.dob), 'years'))
      .filter((age) => age > 0 && age <= 120); // Only include positive ages up to 120

    return {
      men,
      women,
      menPercentage: uniqueByEmail.length ? `${menPercent}%` : '50%',
      womenPercentage: uniqueByEmail.length ? `${womenPercent}%` : '50%',
      menOpacity: men === 0 ? 0.3 : 1,
      womenOpacity: women === 0 ? 0.3 : 1,
      oldest: validAges.length > 0 ? Math.max(...validAges) : '-',
      youngest: validAges.length > 0 ? Math.min(...validAges) : '-',
      average: validAges.length > 0 ? Math.round(validAges.reduce((sum, age) => sum + age, 0) / validAges.length) : '-',
    };
  }, [uniqueByEmail]);

  if (isLoading || categoryLoading) {
    return <ParticipantsListSkeleton />;
  }

  return (
    <Box display="flex" gap={2} height="100%">
      <Box
        sx={{
          width: '100%',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          border: 1,
          borderRadius: '8px',
          borderColor: '#edf1f6',
          background: 'white',
          height: '100%',
        }}
      >
        <Box display="flex" justifyContent="space-between" borderBottom={1} borderColor="#EDF1F6" pb={1}>
          <Box>
            <Typography variant="body2" color="textSecondary">
              Participants
            </Typography>
            <Stack direction="row" gap="15px" alignItems="center">
              <Typography variant="h6">{uniqueByEmail?.length || 0}</Typography>
              <CustomButton size="xs" variant="secondary" sx={{ gap: '5px', background: '#FAFAFA' }} onClick={downloadAllExcel} disabled={isLoading}>
                <ReactSVG src="/images/export.svg" style={{ width: '13px', height: '13px' }} />
                Export
              </CustomButton>
            </Stack>
          </Box>
          <Typography variant="body2" color="textSecondary">
            <CustomButton variant="text" size="small" sx={{ fontWeight: '300', fontSize: '13px' }} onClick={() => navigate('/dashboard/participants')}>
              view all
            </CustomButton>
          </Typography>
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
              <Box width={5} height={5} bgcolor={green[500]} borderRadius="50%" />
              <Typography variant="body2">Men</Typography>
            </Box>
            <Typography variant="font15">{participantStats?.men}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
              <Box width={5} height={5} bgcolor={blue[500]} borderRadius="50%" />
              <Typography variant="body2">Women</Typography>
            </Box>
            <Typography variant="font15">{participantStats?.women}</Typography>
          </Box>
          <Box display="flex" gap={0.5} mt={1} mb={2}>
            <ProgressBar color={green[500]} width={participantStats?.menPercentage} />
            <ProgressBar color={blue[500]} width={participantStats?.womenPercentage} />
          </Box>
        </Box>

        <Divider sx={{ borderColor: '#EDF1F6' }} />

        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              Oldest
            </Typography>
            <Typography variant="font15">{participantStats?.oldest}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              Youngest
            </Typography>
            <Typography variant="font15">{participantStats?.youngest}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              Average age
            </Typography>
            <Typography variant="font15">{participantStats?.average}</Typography>
          </Box>
        </Box>

        <Divider sx={{ borderColor: '#EDF1F6' }} />

        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="heading18">Categories</Typography>
            <Typography variant="body2" color="textSecondary">
              <CustomButton variant="text" size="small" sx={{ fontWeight: '300', fontSize: '13px', p: 0 }} onClick={() => navigate('/dashboard/configure-settings')}>
                view all
              </CustomButton>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={1} mt={2}>
            {categories?.allTournamentCategories?.slice(0, 4)?.map((category, index) => (
              <Box display="flex" justifyContent="space-between" key={index} gap="15px">
                <CustomTooltip title={category.category.category.name || ''} placement="top">
                  <CustomChip size="small" variant="secondary" sx={{ color: 'rgba(10, 37, 64, 0.55)', borderColor: '#EDF1F6' }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      fontSize="12px"
                    >
                      {category.category.category.name || ''}
                    </Typography>
                  </CustomChip>
                </CustomTooltip>
                <Typography variant="font15">{category?.count || 0}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ParticipantsList;
