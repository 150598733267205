import { gql } from '@apollo/client';
const GET_DRAW_MATCHES = gql`
  query drawEntries($filter: ListDrawEntryInput) {
    drawEntries(filter: $filter) {
      id
      date
      draw {
        id
        title
      }
      court {
        name
        id
      }
      winner {
        id
      }
      round
      score
      tournamentCategory {
        id
        category {
          name
          id
          isDisplayClub
        }
      }
      time
      entry1 {
        id
        users {
          id
          user {
            id
            email
            avatar
            name
            surname
            nation
            citizenship
            user_club_id {
              id
              clubName
            }
          }
        }
      }
      entry2 {
        id
        users {
          id
          user {
            id
            email
            avatar
            name
            surname
            nation
            citizenship
            user_club_id {
              id
              clubName
            }
          }
        }
      }
    }
  }
`;
export default GET_DRAW_MATCHES;
