import { Box, ListItemText, MenuItem, Popover, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { externalLinks } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import CustomButton from '../CustomComponents/CustomButton';
import CustomPopover from '../CustomComponents/CustomPopover';
import { ReactSVG } from 'react-svg';

const CreatePopover: FC = () => {
  const { setTournamentId, leagueId } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <CustomPopover
      modalOnMobile
      triggerEl={
        <CustomButton size="small" variant="secondary" sx={{ gap: '4px', background: '#FAFAFA', border: '1px solid #EFEFF0' }}>
          <Add sx={{ fontSize: '16px' }} />
          {t('Create')}
        </CustomButton>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <CustomButton
          size="small"
          variant="menu"
          onClick={() => {
            navigate(`/dashboard/all-tournaments/new${leagueId ? `?leagueId=${leagueId}` : ''}`);
            setTournamentId(null);
          }}
          sx={{ justifyContent: 'flex-start', alignItems: 'center', fontWeight: 500, color: '#0A2540', gap: '5px' }}
        >
          <ReactSVG src="/images/tournament.svg" style={{ width: '15px', height: '15px' }} wrapper="span" />
          {t('Tournament')}
        </CustomButton>
        <CustomButton
          size="small"
          variant="menu"
          onClick={() => window.open(`${externalLinks.addLeague}`)}
          sx={{ justifyContent: 'flex-start', alignItems: 'center', fontWeight: 500, color: '#0A2540', gap: '5px' }}
        >
          <ReactSVG src="/images/league.svg" style={{ width: '15px', height: '15px' }} wrapper="span" />

          {t('League')}
        </CustomButton>
        <CustomButton
          size="small"
          variant="menu"
          onClick={() => window.open(`${externalLinks.addClub}`)}
          sx={{ justifyContent: 'flex-start', alignItems: 'center', fontWeight: 500, color: '#0A2540', gap: '5px' }}
        >
          <ReactSVG src="/images/create-club.svg" style={{ width: '15px', height: '15px' }} wrapper="span" />
          {t('Club')}{' '}
        </CustomButton>
        <CustomButton
          size="small"
          variant="menu"
          onClick={() => window.open(`${externalLinks.addNews}`)}
          sx={{ justifyContent: 'flex-start', alignItems: 'center', fontWeight: 500, color: '#0A2540', gap: '5px' }}
        >
          <ReactSVG src="/images/news.svg" style={{ width: '15px', height: '15px' }} wrapper="span" />
          {t('News')}{' '}
        </CustomButton>
        <CustomButton
          size="small"
          variant="menu"
          onClick={() => window.open(`${externalLinks.addTeam}`)}
          sx={{ justifyContent: 'flex-start', alignItems: 'center', fontWeight: 500, color: '#0A2540', gap: '5px' }}
        >
          <ReactSVG src="/images/team.svg" style={{ width: '15px', height: '15px' }} wrapper="span" />
          {t('Team')}{' '}
        </CustomButton>
      </Box>
    </CustomPopover>
  );
};
export default CreatePopover;
