import { Box, Typography, Chip, Avatar, Stack, Paper, makeStyles, Grid, Divider, Skeleton } from '@material-ui/core';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import LiveIcon from 'src/components/CustomComponents/LiveIcon';
import GetFlag from 'src/components/shared/GetFlag';
import useAuth from 'src/hooks/useAuth';
import { getTimezone, getTimezoneName } from 'src/utils/helper-functions';
import useLocalizedEvent from 'src/utils/useLocalizedEvent';

const social = ['/images/instagram.svg', '/images/facebook.svg', '/images/website.svg'];

const useStyles = makeStyles((theme) => ({
  tournamentTitle: {
    color: '#0A2540',
    fontFamily: 'Eudoxus Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '26px' /* 130% */,
    letterSpacing: '-0.2px',
    maxWidth: '353px',
    // marginBottom: '10px',
  },
  text: {
    color: '#1B3861',
    fontWeight: 'medium',
    whiteSpace: 'pre',
  },
}));

const TournamentHeaderSkeleton = () => (
  <Grid
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      p: 2,
      borderRadius: '8px',
      border: 1,
      borderColor: '#edf1f6',
      background: 'linear-gradient(180deg, rgb(255,255,255) 0%, rgba(255,87,51,0.3) 100%)',
      gap: 2,
    }}
    container
  >
    <Grid sx={{ display: 'flex', gap: 2 }} item xs={12} md={7}>
      <Skeleton variant="rectangular" width={92} height={132} sx={{ borderRadius: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 0, md: 1 }, justifyContent: 'space-between' }}>
        <Stack direction="row" gap="16px">
          {[1, 2, 3].map((item) => (
            <Skeleton key={item} variant="circular" width={32} height={32} />
          ))}
        </Stack>
        <Skeleton variant="text" width={300} height={40} />
        <Box>
          <Stack direction="row" gap={1}>
            <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: 16 }} />
            <Skeleton variant="rectangular" width={120} height={24} sx={{ borderRadius: 16 }} />
            <Skeleton variant="rectangular" width={100} height={24} sx={{ borderRadius: 16 }} />
          </Stack>
        </Box>
      </Box>
    </Grid>

    <Grid
      item
      xs={12}
      md={4}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', md: 'column' },
        gap: 2,
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'flex-end' },
        borderTop: { xs: '1px solid rgba(255, 87, 51, 0.10)', md: 'none' },
        pt: { xs: '20px', md: '0px' },
      }}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Skeleton variant="circular" width={25} height={25} />
          <Stack>
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="text" width={60} height={16} />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Skeleton variant="circular" width={25} height={25} />
          <Stack>
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="text" width={60} height={16} />
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{ textAlign: 'right' }}>
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="text" width={140} height={20} />
        <Skeleton variant="text" width={100} height={16} />
      </Box>
    </Grid>
  </Grid>
);

export const TournamentHeader = () => {
  const classes = useStyles();
  const { tournament, tournamentLoading } = useAuth();

  const isRegistrationClosed =
    tournament?.isRegistrationClosed ||
    tournament?.closeRegistration ||
    tournament?.tournamentCategory?.every((category) => category?.status?.toLowerCase() === 'closed') ||
    tournament?.isRegistrationUpcoming;

  if (tournamentLoading) {
    return <TournamentHeaderSkeleton />;
  }

  return (
    <Grid
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 2,
        borderRadius: '8px',
        border: 1,
        borderColor: '#edf1f6',
        background: 'linear-gradient(180deg, rgb(255,255,255) 0%, rgba(255,87,51,0.3) 100%)',
        gap: 2,
      }}
      container
    >
      <Grid sx={{ display: 'flex', gap: 2 }} item xs={12} md={7}>
        <Avatar variant="rounded" src={tournament?.poster || 'image.png'} sx={{ width: 92, height: 132 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 0, md: 1 }, justifyContent: 'space-between' }}>
          <Stack direction="row" gap="16px">
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              {moment(tournament?.startDate)?.format('ddd, MMM DD, HH:mm')}
              {/* {tz?.abbreviation} */}
            </Typography>
            {/* {social.map((item) => (
              <CustomButton shape="circle" size="small" variant="text">
                <Box component="img" src={item} sx={{ width: '18px', height: '18px' }} />
              </CustomButton>
            ))} */}
          </Stack>
          <Typography
            variant="h6"
            className={classes.tournamentTitle}
            sx={{
              fontSize: { xs: '16px', md: '20px' },
            }}
          >
            {tournament?.title}
          </Typography>
          <Box>
            <Stack direction="row" gap={1} alignItems="center" sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
              <CustomChip
                size="small"
                variant="primary"
                sx={{
                  background: 'linear-gradient(180deg, rgb(255,87,51) 0%, rgb(252.88,75.86,37.93) 47.4%, rgb(249.69,140.88,117.56) 100%)',
                  color: 'white',
                  gap: '6px',
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {tournament?.isOngoing && (
                  <ReactSVG
                    src="/images/stream.svg"
                    wrapper="span"
                    style={{
                      width: '14px',
                      height: '14px',
                    }}
                  />
                )}
                {tournament?.isOngoing ? 'Live' : tournament?.isRegistrationUpcoming ? 'Upcoming' : tournament?.isFinished ? 'Finished' : tournament?.isRegistrationClosed ? 'Reg Closed' : 'Completed'}
              </CustomChip>
              <Stack direction="row" gap={1} alignItems="center">
                <Box
                  component="img"
                  src={tournament?.league?.league?.sports?.icon}
                  alt="icon"
                  sx={{
                    width: '20px',
                    height: '20px',
                    // color: '#ff5733',
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.primary.main, maxWidth: { xs: '150px', md: 'max-content' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {tournament?.league?.league?.title}
                </Typography>
              </Stack>
              {tournament?.country && (
                <Stack direction="row" gap={1} alignItems="center">
                  <GetFlag country={tournament?.country} style={{ width: '18px', height: '18px' }} imgStyle={{ borderRadius: '6px' }} />
                  <Typography variant="body2" sx={{ color: '#0a2540' }}>
                    {`${tournament?.country || ''}${tournament?.city ? ', ' + tournament?.city : ''}`}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', md: 'column' },
          gap: 2,
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', md: 'flex-end' },
          borderTop: { xs: '1px solid rgba(255, 87, 51, 0.10)', md: 'none' },
          pt: { xs: '20px', md: '0px' },
        }}
      >
        <Stack direction="row" gap={1} alignItems="center" sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomAvatar src={tournament?.organizer?.member?.avatar} size={25} seed="Nikita Ribakovs" />
            <Stack>
              <Typography variant="font15" sx={{ maxWidth: { xs: '67px', md: 'max-content' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {tournament?.organizer?.member?.name ? `${tournament?.organizer?.member?.name?.charAt(0)?.toUpperCase()}. ${tournament?.organizer?.member?.surname}` : 'N/A'}
              </Typography>
              <Typography variant="font21" sx={{ color: '#0a25408c' }}>
                Organizer
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
            <CustomAvatar src={tournament?.referee?.referee?.avatar} size={25} seed="M. Samoilovs" />
            <Stack>
              <Typography variant="font15" sx={{ maxWidth: { xs: '67px', md: 'max-content' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {tournament?.referee?.referee?.name ? `${tournament?.referee?.referee?.name?.charAt(0)?.toUpperCase()}. ${tournament?.referee?.referee?.surname}` : 'N/A'}
              </Typography>
              <Typography variant="font21" sx={{ color: '#0a25408c' }}>
                Main referee
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box sx={{ textAlign: 'right' }}>
          {tournament?.isRegistrationUpcoming ? (
            <>
              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                {moment?.utc(tournament?.registrationStartDate)?.format('ddd, MMM DD, HH:mm')}
                {/* {tz?.abbreviation} */}
              </Typography>
              <Typography variant="caption" sx={{ color: '#0a25408c' }}>
                Registration starts on
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                {moment(tournament?.entryDeadline)?.format('ddd, MMM DD, HH:mm')}
                {/* {tz?.abbreviation} */}
              </Typography>
              <Typography variant="caption" sx={{ color: '#0a25408c' }}>
                Registration {tournament?.isRegistrationClosed ? 'closed' : 'closes'} on
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
