import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';
import Cookies from 'js-cookie';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ME, TOURNAMENT_BY_ID } from 'src/graphql/queries';
import React from 'react';
import client from 'src/utils/apolloClient';
import { TournamentDetailByIdQuery } from 'src/__generated__/graphql';
import { jwtDecode } from 'jwt-decode';
import { GET_OTP } from 'src/graphql/mutations';
import useLocalizedEvent, { LocalizedTournament } from 'src/utils/useLocalizedEvent';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  tournamentId: number;
  leagueId: number;
  tournament: LocalizedTournament | null;
  tournamentLoading: boolean;
  openOTPModal: boolean;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (user: any) => Promise<void>;
  logout: () => Promise<void>;
  register: (user: any) => Promise<void>;
  setTournamentId: (id: number | string, league?: number | string) => Promise<void>;
  getTournamentData: ({}) => Promise<void>;
  setOpenOTPModal: (isOpen: boolean) => void;
  refetchTournament: (id: number) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    openOTPModal?: boolean;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    isAuthenticated: boolean;
  };
};

type SetOTPModalAction = {
  type: 'SET_OTP_MODAL';
  payload: {
    openOTPModal: boolean;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};
type tournamentAction = {
  type: 'GET_TOURNAMENT_ID';
  payload: {
    id: number;
    league?: number;
  };
};

type tournamentObjectAction = {
  type: 'GET_TOURNAMENT';
  payload: {
    tournament: LocalizedTournament;
  };
};

type SetTournamentLoadingAction = {
  type: 'SET_TOURNAMENT_LOADING';
  payload: boolean;
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction | tournamentAction | tournamentObjectAction | SetOTPModalAction | SetTournamentLoadingAction;

let isAuthenticated = false;
let user = null;
let tournamentId = null;
let leagueId = null;

try {
  isAuthenticated = Cookies.get('isAuthenticated') ? JSON.parse(Cookies.get('isAuthenticated') as any) : false;
} catch (err) {
  console.error('Error parsing isAuthenticated:', err);
}

try {
  user = Cookies.get('user') ? JSON.parse(Cookies.get('user') as any) : null;
} catch (err) {
  console.error('Error parsing user:', err);
}

try {
  tournamentId = Cookies.get('tournamentId') && JSON.parse(Cookies.get('tournamentId'));
  leagueId = Cookies.get('leagueId') && JSON.parse(Cookies.get('leagueId'));
} catch (err) {
  console.error('Error parsing tournamentId|leagueId:', err);
}

const initialState: State = {
  isAuthenticated,
  user,
  isInitialized: false,
  tournamentId,
  leagueId: null,
  tournament: null,
  tournamentLoading: true,
  openOTPModal: false,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, openOTPModal } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      openOTPModal: openOTPModal ?? false,
    };
  },
  SET_OTP_MODAL: (state: State, action: any): any => {
    const { openOTPModal } = action.payload;

    return {
      ...state,
      openOTPModal,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, isAuthenticated } = action.payload;

    return {
      ...state,
      isAuthenticated: isAuthenticated,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    tournamentId: null,
    leagueId: null,
    tournament: null,
    openOTPModal: false,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  GET_TOURNAMENT_ID: (state: State, action: any): any => {
    const { id, league } = action.payload;

    return {
      ...state,
      tournamentId: id,
      ...(league ? { leagueId: league } : {}),
    };
  },
  GET_TOURNAMENT: (state: State, action: tournamentObjectAction): State => {
    const { tournament } = action.payload;

    return {
      ...state,
      tournament,
    };
  },
  SET_TOURNAMENT_LOADING: (state: State, action: any): State => ({
    ...state,
    tournamentLoading: action.payload,
  }),
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setTournamentId: () => Promise.resolve(),
  getTournamentData: () => Promise.resolve(),
  setOpenOTPModal: (isOpen: boolean) => {},
  refetchTournament: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth] = useLazyQuery(ME);
  const [getOTPThroughToken] = useMutation(GET_OTP);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = Cookies.get('token') as string;
        const getAuthenticated = Cookies.get('isAuthenticated') && Cookies.get('isAuthenticated') === 'true';
        if (accessToken) {
          const response = await auth();
          const isAuthenticated = getAuthenticated ? true : response?.data?.getProfile?.isAuthenticationRequired ? false : true;
          if (!isAuthenticated) {
            await getOTPThroughToken();
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: response?.data?.getProfile,
                openOTPModal: true,
              },
            });
            return;
          }
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: isAuthenticated,
              user: response.data.getProfile,
              openOTPModal: false,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              openOTPModal: false,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            openOTPModal: false,
          },
        });
      }
    };

    initialize();
  }, []);

  const setOpenOTPModal = (isOpen) => {
    dispatch({
      type: 'SET_OTP_MODAL',
      payload: {
        openOTPModal: isOpen ?? false,
      },
    });
  };

  const login = async (user: any): Promise<void> => {
    if (user?.accessToken) {
      const { exp } = jwtDecode(user.accessToken);

      Cookies.set('isAuthenticated', JSON.stringify(true), { expires: new Date(exp * 1000) });
      Cookies.set('token', user.accessToken);
    }
    Cookies.set('user', JSON.stringify(user));

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        isAuthenticated: user?.accessToken ? true : false,
      },
    });
  };

  const logout = async (): Promise<void> => {
    Cookies.remove('user');
    Cookies.set('isAuthenticated', JSON.stringify(false));
    Cookies.remove('token');
    Cookies.remove('tournamentId');
    Cookies.remove('leagueId');
    client.resetStore();
    dispatch({ type: 'LOGOUT' });
  };

  const [fetchTournamentDetails, { data: tournamentData }] = useLazyQuery(TOURNAMENT_BY_ID);

  const setTournamentId = async (id: number, league?: number): Promise<void> => {
    try {
      dispatch({ type: 'SET_TOURNAMENT_LOADING', payload: true });

      Cookies.set('tournamentId', String(id));
      Cookies.set('leagueId', String(league));

      dispatch({
        type: 'GET_TOURNAMENT_ID',
        payload: { id, league },
      });

      if (id) {
        const response = await fetchTournamentDetails({
          variables: { id },
        });

        const tournament = useLocalizedEvent(response?.data?.tournamentDetailById, ['endDate', 'startDate', 'withdrawlDeadline', 'entryDeadline', 'registrationStartDate']);

        if (response.data) {
          dispatch({
            type: 'GET_TOURNAMENT',
            payload: { tournament },
          });
        }
        dispatch({ type: 'SET_TOURNAMENT_LOADING', payload: false });
      }
    } catch (error) {
      dispatch({ type: 'SET_TOURNAMENT_LOADING', payload: false });
      console.error('Error fetching tournament:', error);
    }
  };
  const register = async (user: any): Promise<void> => {
    Cookies.set('isAuthenticated', JSON.stringify(true));
    Cookies.set('token', user.accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const getTournamentData = async (data): Promise<void> => {
    dispatch({
      type: 'GET_TOURNAMENT',
      payload: {
        tournament: data,
      },
    });
  };

  const refetchTournament = async (id: number): Promise<void> => {
    try {
      dispatch({ type: 'SET_TOURNAMENT_LOADING', payload: true });

      const response = await fetchTournamentDetails({
        variables: { id },
      });

      const tournament = useLocalizedEvent(response?.data?.tournamentDetailById, ['endDate', 'startDate', 'withdrawlDeadline', 'entryDeadline', 'registrationStartDate']);

      if (response.data) {
        dispatch({
          type: 'GET_TOURNAMENT',
          payload: { tournament },
        });
      }
    } catch (error) {
      console.error('Error refetching tournament:', error);
    } finally {
      dispatch({ type: 'SET_TOURNAMENT_LOADING', payload: false });
    }
  };

  return (
    <React.Fragment>
      <AuthContext.Provider
        value={{
          ...state,
          platform: 'JWT',
          login,
          logout,
          register,
          setTournamentId,
          getTournamentData,
          setOpenOTPModal,
          refetchTournament,
        }}
      >
        {children}
      </AuthContext.Provider>
    </React.Fragment>
  );
};

export default AuthContext;
