import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Box, CssBaseline, IconButton, ThemeProvider } from '@material-ui/core';
import './i18n';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import './assets/fonts/montserrat/stylesheet.css';
import './assets/fonts/roboto/stylesheet.css';
import './assets/fonts/eudoxus-sans/stylesheet.css';
import './assets/fonts/Inter/stylesheet.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import Cookies from 'js-cookie';
import { Wifi, WifiOff } from '@material-ui/icons';
import moment from 'moment-timezone';
moment.tz.setDefault('UTC');

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOnline = () => {
      toast.success(
        <>
          <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
            <Box sx={{ fontSize: '18px' }}>You're online now</Box>
            <Box sx={{ fontSize: '16px', color: '#878787' }}>Hurray! Internet is connected.</Box>
          </Box>
        </>,
        {
          duration: 5000,
          icon: (
            <IconButton sx={{ background: '#2ecc71', color: '#ffff', padding: '10px' }}>
              <Wifi />
            </IconButton>
          ),
          style: {
            borderLeft: '5px solid #2ecc71',
            height: '60px',
          },
        },
      );
    };

    const handleOffline = () => {
      toast.error(
        <>
          <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
            <Box sx={{ fontSize: '18px' }}>You're offline now</Box>
            <Box sx={{ fontSize: '16px', color: '#878787' }}>Opps! Internet is disconnected.</Box>
          </Box>
        </>,
        {
          duration: 5000,
          icon: (
            <IconButton sx={{ background: '#ccc', color: '#ffff', padding: '10px' }}>
              <WifiOff />
            </IconButton>
          ),
          style: {
            borderLeft: '5px solid #ccc',
            height: '60px',
          },
        },
      );
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useScrollReset();
  const tokenParam = new URLSearchParams(window.location.search).get('token');
  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  useEffect(() => {
    if (tokenParam) {
      Cookies.set('token', tokenParam);
    }
  }, [tokenParam]);

  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  let decodedMCode = null;
  const isMaintenanceEnabled = process.env.REACT_APP_MAINTENANCE_ENABLED === 'true';

  try {
    const mCode = Cookies.get('mCode');
    decodedMCode = mCode ? window.atob(mCode) : null;
  } catch (error) {
    console.error('Error decoding mCode:', error);
  }

  useEffect(() => {
    if (isMaintenanceEnabled && (!decodedMCode || decodedMCode !== process.env.REACT_APP_MAINTENANCE_CODE)) {
      navigate('/authentication/maintenance-code', { replace: true });
    }
  }, [isMaintenanceEnabled, decodedMCode, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />

      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
