import { gql } from '@apollo/client';

const CREATE_TOURNAMENT = gql`
  mutation createTournament(
    $league: Float!
    $title: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $registrationStartDate: DateTime
    $country: String!
    $city: String!
    $address: String!
    $club: Int
    $entryFee: String!
    $onsiteEntryFee: Float
    $organizer: Float!
    $referee: Float
    $additionalReferee: [Int!]
    $registerLink: String
    $description: String!
    $entryDeadline: DateTime!
    $timeZone: String!
    $isFeatured: Boolean
    $termsAndConditions: String
    $customSuccessMessage: String
    $customInfoMessage: String
    $tournamentCategory: [JSONObject!]
    $coverPhoto: Upload
    $logo: Upload
    $regulations: Upload
    $totalCourts: Int
    $website: String
    $registeredUsersInTournament: Boolean
    $closeRegistration: Boolean!
    $approvedEntries: Boolean
    $partnerSearch: Boolean
    $loggedInUser: Boolean
    $withdrawlDeadline: DateTime!
    $currency: String!
    $status: String!
    $additionalQuestion: [JSONObject!]
    $videoUrl: String
    $contactUrl: String
    $makePrivate: Boolean
    $password: String
    $enablePracticeCourtsBooking: Boolean
    $enableTransferBooking: Boolean
    $enableLiveStream: Boolean
    $enableLiveScoring: Boolean
    $paymentMethod: String
    $paymentType: String
    $facts: [JSONObject!]
    $poster: Upload
    $resourceTitle: String
    $timeControl: String
    $showTournamentChessManagerCalendar: Boolean
    $localRatedTournament: Boolean
    $fideRatedTournament: Boolean
    $autoConfirmEntries: Boolean
    $isDuprRatingEnabled: Boolean
    $isDuprRequired: Boolean
    $isRegistrationEmailsEnabled: Boolean
    $isOrganizerRegEmailsEnabled: Boolean
    $isPayForMyselfEnabled: Boolean
    # $isManagerPayingServiceFee: Boolean
    $isManagerPayingStripeFee: Boolean
    $isManagerPayingPlatformFee: Boolean
    $discounts: [JSONObject!]
    $events: String
    $redirectURL: String
    $isHideFromCalendar: Boolean
  ) {
    createTournament(
      redirectURL: $redirectURL
      createTournamentInput: {
        logo: $logo
        league: $league
        title: $title
        startDate: $startDate
        description: $description
        entryDeadline: $entryDeadline
        timeZone: $timeZone
        termsAndConditions: $termsAndConditions
        customSuccessMessage: $customSuccessMessage
        customInfoMessage: $customInfoMessage
        endDate: $endDate
        registrationStartDate: $registrationStartDate
        country: $country
        address: $address
        club: $club
        city: $city
        entryFee: $entryFee
        onsiteEntryFee: $onsiteEntryFee
        organizer: $organizer
        additionalReferee: $additionalReferee
        referee: $referee
        registerLink: $registerLink
        tournamentCategory: $tournamentCategory
        coverPhoto: $coverPhoto
        regulations: $regulations
        totalCourts: $totalCourts
        website: $website
        registeredUsersInTournament: $registeredUsersInTournament
        closeRegistration: $closeRegistration
        approvedEntries: $approvedEntries
        partnerSearch: $partnerSearch
        loggedInUser: $loggedInUser
        withdrawlDeadline: $withdrawlDeadline
        currency: $currency
        status: $status
        additionalQuestion: $additionalQuestion
        videoUrl: $videoUrl
        contactUrl: $contactUrl
        makePrivate: $makePrivate
        password: $password
        enablePracticeCourtsBooking: $enablePracticeCourtsBooking
        enableTransferBooking: $enableTransferBooking
        enableLiveStream: $enableLiveStream
        enableLiveScoring: $enableLiveScoring
        isFeatured: $isFeatured
        paymentMethod: $paymentMethod
        paymentType: $paymentType
        facts: $facts
        poster: $poster
        resourceTitle: $resourceTitle
        timeControl: $timeControl
        showTournamentChessManagerCalendar: $showTournamentChessManagerCalendar
        localRatedTournament: $localRatedTournament
        fideRatedTournament: $fideRatedTournament
        autoConfirmEntries: $autoConfirmEntries
        isDuprRatingEnabled: $isDuprRatingEnabled
        isDuprRequired: $isDuprRequired
        isRegistrationEmailsEnabled: $isRegistrationEmailsEnabled
        isOrganizerRegEmailsEnabled: $isOrganizerRegEmailsEnabled
        isPayForMyselfEnabled: $isPayForMyselfEnabled
        # isManagerPayingServiceFee: $isManagerPayingServiceFee
        isManagerPayingStripeFee: $isManagerPayingStripeFee
        isManagerPayingPlatformFee: $isManagerPayingPlatformFee
        discounts: $discounts
        events: $events
        isHideFromCalendar: $isHideFromCalendar
      }
    ) {
      id
      title
      startDate
      description
      entryDeadline
      timeZone
      termsAndConditions
      endDate
      courts {
        name
      }
      country
      address
      city
      entryFee
      registerLink
      withdrawlDeadline
      currency
      status
      events
    }
  }
`;

export default CREATE_TOURNAMENT;
