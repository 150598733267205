import { Dispatch, FC, SetStateAction, Fragment, useCallback, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import CustomButton from '../../CustomComponents/CustomButton';
import { Skeleton, Stack } from '@material-ui/core';
import CustomChip from '../../CustomComponents/CustomChip';
import chroma from 'chroma-js';

interface SidebarItemProps {
  icon: string;
  label: string;
  open: boolean;
  count?: number;
  isLoading?: boolean;
  link: string;
  onMobileClose: () => void;
}

const SidebarItem: FC<SidebarItemProps> = ({ icon, label, open, isLoading, count, link, onMobileClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = link?.split('?')[0] === location.pathname;

  const handleNavigation = useCallback(() => {
    link.includes('gallery') ? window.open(link, '_blank') : navigate(link);
    onMobileClose();
  }, [link, navigate, onMobileClose]);

  return (
    <Fragment>
      {isLoading ? (
        <Skeleton variant="rectangular" animation="wave" width="100%" height="40px" sx={{ my: '10px', borderRadius: '8px' }} />
      ) : (
        <ListItem
          sx={{
            width: '100%',
            height: '40px',
            mb: '10px',
            mt: '10px',
            padding: '0px',
            borderRadius: '8px',
          }}
        >
          <CustomButton variant="menu" size="medium" onClick={handleNavigation} selected={isActive} sx={{ width: '100%', justifyContent: !open ? 'center' : 'space-between' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <ListItemIcon sx={{ marginRight: !open ? 0 : '12px', width: '16px', height: '16px', color: 'inherit' }}>
                <ReactSVG src={icon} wrapper="span" />
              </ListItemIcon>
              {open && label}
            </Stack>
            {count !== undefined ? (
              <CustomChip
                variant="menu"
                size="xs"
                selected={!open}
                sx={{
                  marginLeft: 'auto',
                  backgroundColor: (theme) => (open ? 'rgba(10, 37, 64, 0.05)' : chroma(theme.palette.primary.main).alpha(0.15).hex()),
                  color: (theme) => (open ? 'rgba(10, 37, 64, 0.55)' : theme.palette.primary.main),
                  borderRadius: '8px',
                  padding: '0px 8px',
                  position: open ? 'relative' : 'absolute',
                  right: open ? 'auto' : '4px',
                  top: !open ? '-4px' : 'auto',
                  transform: !open ? 'translateX(30%)' : 'none',
                }}
              >
                {count ?? 0}
              </CustomChip>
            ) : null}
          </CustomButton>
        </ListItem>
      )}
    </Fragment>
  );
};

export default SidebarItem;
