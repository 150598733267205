import ReactDOM from 'react-dom';
import { Box, Fade, Grow, Slide, Paper, Typography } from '@material-ui/core';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { cloneElement, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowBack } from '@material-ui/icons';
import { SxProps } from '@material-ui/system';
import useDebounce from 'src/hooks/useDebounce';

type Props = {
  onClose?: () => void;
  onOpen?: () => void;
  triggerEl?: ReactElement | ((open: boolean) => ReactElement);
  children: ((onClose: () => void, open: boolean, backButtonClickCounter: number, setBackButtonClickCounter: (state: number) => void) => ReactNode) | ReactNode;
  title?: ReactNode;
  handleBack?: boolean;
  openOvveride?: boolean;
  preventClosing?: (() => void) | boolean;
  allowClosing?: boolean;
  backgroundSx?: SxProps;
  onClick?: () => void;
};

const CustomModal = ({ onClose, onOpen, triggerEl, children, title, handleBack, openOvveride, preventClosing, backgroundSx, allowClosing = true, onClick }: Props) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [backButtonClickCounter, setBackButtonClickCounter] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [delayedOpen, setDelayedOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const open = isOpen || openOvveride;
  const handleClose = () => {
    if (preventClosing || !allowClosing) {
      if (typeof preventClosing === 'function') preventClosing();
      return;
    }

    setIsOpen(false);
    onClose?.();
  };

  useDebounce(
    () => {
      if (open) {
        setDelayedOpen(true); // Set delayed open after 0.5 seconds
      } else {
        setDelayedOpen(false);
      }
    },
    [open],
    200,
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  const renderContent = (
    <Paper
      sx={{
        height: 'fit-content',
        maxWidth: { xs: '100dvw', sm: '90dvw' },
        borderRadius: '16px',
        padding: { xs: '16px', sm: '22px' },
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        zIndex: 1001,
        minHeight: { xs: '60dvh', sm: 0 },
        maxHeight: '90dvh',
        borderBottomLeftRadius: { xs: 0, sm: '16px' },
        borderBottomRightRadius: { xs: 0, sm: '16px' },
        width: { xs: '100%', sm: 'fit-content' },
        transition: 'all 0.5s',
      }}
    >
      <Box sx={{ height: 'fit-content', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {(handleBack || allowClosing || title) && (
          <Box
            sx={{
              width: '100%',
              height: '40px',
              display: 'flex',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {handleBack && (
              <CustomButton size="medium" variant="text" shape="circle" onClick={() => setBackButtonClickCounter((prev) => prev + 1)}>
                <ArrowBack />
              </CustomButton>
            )}
            <div>{title && <Typography variant="font22">{title}</Typography>}</div>
            {allowClosing && (
              <CustomButton size="medium" variant="text" shape="circle" onClick={handleClose}>
                <CloseIcon />
              </CustomButton>
            )}
          </Box>
        )}
        <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          {typeof children === 'function' ? children(handleClose, open, backButtonClickCounter, setBackButtonClickCounter) : children}
        </Box>
      </Box>
    </Paper>
  );

  return (
    <>
      {triggerEl &&
        cloneElement(typeof triggerEl === 'function' ? triggerEl(open) : triggerEl, {
          onClick: () => {
            setIsOpen(true);
            onClick?.();
          },
        })}
      {(open || isAnimating) &&
        ReactDOM.createPortal(
          <Box
            sx={{
              position: 'fixed',
              inset: 0, // This ensures the modal covers the full screen without needing a high z-index
              zIndex: 1300,
              minHeight: '100dvh',
              minWidth: '100dvw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: { xs: 'end', sm: 'center' },
            }}
          >
            <Fade in={open} timeout={{ enter: 500, exit: 500 }} onEntered={() => setIsAnimating(true)} onExited={() => setIsAnimating(false)}>
              <Box
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  backdropFilter: 'blur(3px)',
                  background: 'rgba(0, 0, 0, 0.2)',
                  ...backgroundSx,
                }}
                onClick={handleClose}
              />
            </Fade>
            {isMobile ? (
              <Slide in={open ? delayedOpen : false} direction="up">
                {renderContent}
              </Slide>
            ) : (
              <Grow in={open} timeout={{ enter: 250, exit: 250 }}>
                {renderContent}
              </Grow>
            )}
          </Box>,
          document.body,
        )}
    </>
  );
};

export default CustomModal;
