import { Box, Grid, GridSize, Paper, Skeleton, Stack, Typography } from '@material-ui/core';
import { Fragment } from 'react';
import useAuth from 'src/hooks/useAuth';

const FactSheetSkeleton = () => (
  <Box sx={{ p: 2, borderRadius: '8px', border: 1, borderColor: '#edf1f6', background: 'white' }}>
    <Skeleton variant="text" width={100} height={24} sx={{ mb: '14px' }} />
    <Stack
      flexDirection="row"
      overflow="auto"
      gap="14px"
      width="100%"
      sx={{
        maxWidth: '100%',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#ccc',
          borderRadius: '4px',
        },
        justifyContent: 'space-between',
      }}
    >
      {[1, 2, 3, 4].map((_, index) => (
        <Box
          key={index}
          sx={{
            minWidth: '181px',
            maxWidth: '181px',
            padding: '12px 15px',
            borderRadius: '8px',
            border: '1px solid #EDF1F6',
            background: '#FEFEFE',
            marginBottom: '20px',
          }}
        >
          <Skeleton variant="text" width={120} height={24} sx={{ mb: 1 }} />
          <Skeleton variant="text" width={150} height={20} />
        </Box>
      ))}
    </Stack>
  </Box>
);

export const FactSheet = ({ facts }) => {
  const { tournament, tournamentLoading } = useAuth();
  if (tournamentLoading) {
    return <FactSheetSkeleton />;
  }
  return (
    <Box sx={{ p: 2, borderRadius: '8px', border: 1, borderColor: '#edf1f6', background: 'white' }}>
      <Typography variant="h6" sx={{ fontWeight: '600', mb: '14px', fontSize: '16px' }}>
        Fact sheet
      </Typography>

      <Stack
        flexDirection="row"
        overflow="auto"
        gap="14px"
        width="100%"
        sx={{
          maxWidth: '100%',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: '4px',
          },
          justifyContent: 'space-between',
        }}
      >
        {facts?.map((fact, index) => (
          <Fragment key={index}>
            <Box
              sx={{
                minWidth: '181px',
                maxWidth: '181px',
                display: 'flex',
                padding: '12px 15px',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '5px',
                flex: '1 0 0',
                borderRadius: '8px',
                border: '1px solid #EDF1F6',
                background: '#FEFEFE',
                marginBottom: '20px',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: '600', fontSize: '16px', marginBottom: '5px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {fact?.factSheet?.fieldTitle}
              </Typography>
              <Typography variant="body2" sx={{ color: '#0a25408c', marginBottom: '5px' }}>
                {fact?.answer}
              </Typography>
            </Box>
          </Fragment>
        ))}
      </Stack>
    </Box>
  );
};
