import type { AppBarProps, Theme } from '@material-ui/core';
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import { Dashboard, DirectionsRun } from '@material-ui/icons';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { USER_ROLES } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import CustomButton from '../CustomComponents/CustomButton';
import WarningPopup from '../shared/WarningPopup';
import AccountPopover from './AccountPopover';
import ContentSearch from './ContentSearch';
import CreatePopover from './CreatePopover';
import LanguagePopover from './LanguagePopover';
import HelpMenu from './HelpMenu';

const allowedRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.FEDERATION];

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'inline-block',
  ...(theme.palette.mode === 'light' && {
    backgroundColor: 'white',
    borderTop: '1px solid #EFEFF0',
    borderBottom: '1px solid #EFEFF0',
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,

    width: '-webkit-fill-available',
    '@media (min-width: 1280px)': {
      // paddingTop: '11px',
      paddingLeft: '12px',
    },
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #EFEFF0',
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,

    height: '90px',
    '@media (min-width: 1280px)': {
      // paddingTop: '11px',
      // paddingBottom: '11px',
      paddingLeft: '12px',
    },
  }),
  zIndex: 1100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const [warning, setWarning] = useState(false);
  const { onSidebarMobileOpen, ...other } = props;
  const { logout, leagueId, tournamentId } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      navigate('/dashboard/all-tournaments');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const handleOpenDashboard = () => {
    const token = Cookies.get('token');
    const baseUrl = `${process.env.REACT_APP_ADMIN_URL}/dashboard`;
    const url = leagueId ? `${baseUrl}?league=${leagueId}&token=${token}` : `${baseUrl}?token=${token}`;

    window.open(url);
  };

  const auth = useAuth();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: '60px !important', alignItems: 'center', paddingLeft: '13px !important', paddingRight: '20px !important' }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            },
            ml: {
              xs: '-20px',
            },
          }}
        >
          <ReactSVG src="/images/smallLogo.svg" />
          <ReactSVG src="/images/ham.svg" className="sidebar-toggler" />
        </IconButton>

        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'none',
            },
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <CustomButton
            variant="menu"
            size="small"
            onClick={() => {
              navigate('/dashboard/all-tournaments');
            }}
            selected={location.pathname === '/dashboard/all-tournaments'}
            sx={{ gap: '4px' }}
          >
            <ReactSVG src="/images/tournament.svg" style={{ width: '20px', height: '20px' }} />
            All Tournaments
          </CustomButton>
          <HelpMenu />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: '15px',
            alignItems: 'center',
            ml: '88px',
            '@media (max-width: 1490px)': {
              ml: '10px',
            },
          }}
        >
          {/* <Box>
            <NotificationsPopover />
            </Box> */}
          <CreatePopover />
          {mdUp && (
            <CustomButton
              size="small"
              variant="secondary"
              sx={{ gap: '4px', background: '#FAFAFA', border: '1px solid #EFEFF0' }}
              onClick={() => {
                window.open(process.env.REACT_APP_CLIENT_URL);
              }}
            >
              <ReactSVG src="/images/users.svg" style={{ width: '16px', height: '16px' }} />
              {mdUp ? t('Athlete Portal') : ''}
            </CustomButton>
          )}

          {allowedRoles.includes(auth?.user?.role) && (
            <CustomButton size="small" variant="secondary" sx={{ gap: '4px', background: '#FAFAFA', border: '1px solid #EFEFF0' }} onClick={handleOpenDashboard}>
              <ReactSVG src="/images/dashboard-icon.svg" style={{ width: '14px', height: '14px' }} wrapper="span" />

              {t('Dashboard')}
            </CustomButton>
          )}
        </Box>

        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'none',
            },
            ml: '5px',
          }}
        >
          <LanguagePopover />
        </Box>

        <Box
          sx={{
            ml: {
              lg: 'auto',
              xs: 2,
            },
          }}
        >
          <AccountPopover />
        </Box>
      </Toolbar>
      <WarningPopup
        openWarningModal={warning}
        handleClose={() => setWarning(false)}
        handleAction={handleLogout}
        warningTitle="Confirm logout"
        warningText="Are you sure you want to logout?"
        confirmText="Logout"
      />
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
