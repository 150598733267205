import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

interface LoadableImageProps {
  imageSrc: string;
  fallbackImageSrc?: string;
  alt: string;
  loader: React.ReactNode;
  sx?: SxProps;
}

const LoadableImage: FC<LoadableImageProps> = ({ imageSrc, fallbackImageSrc, alt, loader, sx }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
    setImageError(false);
  }, [imageSrc, fallbackImageSrc]);

  const currentSrc = imageError && fallbackImageSrc ? fallbackImageSrc : imageSrc;

  return (
    <Fragment>
      <img alt="image_placeholder" src={currentSrc} style={{ display: 'none' }} onLoad={() => setImageLoaded(true)} onError={() => setImageError(true)} />
      {imageLoaded ? <Box component="img" src={currentSrc} alt={alt} sx={sx} /> : loader}
    </Fragment>
  );
};

export default LoadableImage;
