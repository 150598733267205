import { gql } from '@apollo/client';

const TRANSACTIONS = gql`
  query allTransactionHistory($tournament: Float, $platform: Float, $filter: ListTransactionHistory, $paymentTypes: [TransactionType!]) {
    allTransactionHistory(tournament: $tournament, platform: $platform, filter: $filter, paymentTypes: $paymentTypes) {
      id
      createdAt
      user {
        name
        surname
        email
        phone
        id
        avatar
      }
      license {
        title
        fee
      }
      league {
        title
      }
      platform {
        title
      }
      amount
      paidAmount
      currency
      productType
      paymentStatus
      transactionType
      tournatedFee
      partnerFee
      processingFee
      # tournamentRegistration {
      #   categoryId {
      #     id
      #     category {
      #       name
      #       id
      #     }
      #   }
      # }
      tournament {
        title
        id
      }
    }
  }
`;
export default TRANSACTIONS;
