import { Box } from '@material-ui/core';

export const DUPRBox = ({ rating, color }: { rating: string; color: string }) => (
  <Box
    sx={{
      backgroundColor: color,
      color: '#ffff',
      cursor: 'pointer',
      fontSize: '10px',
      fontWeight: 700,
      fontFamily: 'Inter',
      padding: '4px 6px',
      lineHeight: '100%',
      borderRadius: '4px',
      textAlign: 'center',
      width: 'fit-content',
      display: 'flex',
      gap: '2px',
      alignItems: 'center',
    }}
  >
    <Box component="img" height="8px" sx={{ pointerEvents: 'none' }} src="https://i.imgur.com/XorD1mC.png" />
    {rating}
  </Box>
);
