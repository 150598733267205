import React, { Dispatch, Fragment, SetStateAction, useCallback, useRef, useState } from 'react';
import { Autocomplete, Box, Button, CircularProgress, ListItem, ListItemText, Menu } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { League } from 'src/__generated__/graphql';
import { Tournament } from 'src/__generated__/graphql';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import useAuth from 'src/hooks/useAuth';
import ChevronDown from 'src/icons/ChevronDown';
import ExternalLink from 'src/icons/ExternalLink';

const TournamentSelection: React.FC<{
  tournaments: Tournament[];
  loading: boolean;
  setSelectedTournament: Dispatch<SetStateAction<Tournament>>;
  setLeague: Dispatch<SetStateAction<League>>;
  setPage: Dispatch<SetStateAction<number>>;
  tournamentIdParam: string;
  openSideBarState: boolean;
  selectedTournament: Tournament;
  setSearchTitle: Dispatch<SetStateAction<string>>;
  hasMore: boolean;
  setHasMore: Dispatch<SetStateAction<boolean>>;
  isLoadingParamTournament: boolean;
}> = ({ tournaments, loading, setSelectedTournament, setLeague, setPage, tournamentIdParam, openSideBarState, selectedTournament, setSearchTitle, hasMore, setHasMore, isLoadingParamTournament }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { setTournamentId } = useAuth();
  const location = useLocation();
  const observer: any = useRef();

  const lastOptionElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  const handleCopyLink = () => {
    //copy link to clipboard`
    // const el = document.createElement('textarea');

    // // el.value = `${window.location.origin}${location.pathname}${tournamentIdParam}`;
    // el.value = `${selectedTournament?.platform?.platformLink || process.env.REACT_APP_CLIENT_URL}/tournament/${selectedTournament?.id}`;
    // document.body.appendChild(el);
    // el.select();
    // document.execCommand('copy');
    // document.body.removeChild(el);
    // toast.success('Tournament Link copied to clipboard');
    window.open(`${selectedTournament?.platform?.platformLink || process.env.REACT_APP_CLIENT_URL}/tournament/${selectedTournament?.id}`, '_blank');
    return;
  };
  return openSideBarState ? (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        mt: '12px',
      }}
    >
      <Autocomplete
        id="combo-box-demo"
        options={tournaments ?? []}
        loading={loading}
        disableClearable
        sx={{
          '& .MuiAutocomplete-endAdornment': {
            top: 'auto',
          },
        }}
        noOptionsText="No tournament found"
        onChange={(e, value: Tournament | null) => {
          if (!value?.id) {
            setTournamentId(null, null);
            setSelectedTournament(null);
            location.pathname !== '/dashboard/all-tournaments/new' && navigate('/dashboard/all-tournaments');
            return;
          }
          // handleGetId(value?.id);
          setLeague(value?.league?.league);
          setSelectedTournament(value);
          setTournamentId(value?.id, value?.league?.league?.id);
          navigate(`/dashboard?tournament=${value?.id}`);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        value={selectedTournament || null}
        getOptionLabel={(option) => `${option?.title} [${moment.utc(option?.startDate).format('DD MMMM YYYY')} - ${moment.utc(option?.endDate).format('DD MMMM YYYY')}]`}
        renderOption={(props, option) => (
          <li {...props} key={option?.id} ref={lastOptionElementRef}>
            {`${option?.title} [${moment.utc(option?.startDate).format('DD MMMM YYYY')} - ${moment.utc(option?.endDate).format('DD MMMM YYYY')}]`}
          </li>
        )}
        fullWidth
        popupIcon={<ChevronDown sx={{ color: '#86909F !important', fontSize: '20px' }} />}
        renderInput={(params) => (
          <CustomFormInput
            placeholder="Select Tournament"
            variant="outlined"
            onChange={(e) => {
              if (e.target.value === '') setHasMore(true);
              setPage(0);
              setSearchTitle(e.target.value);
            }}
            sx={{
              backgroundColor: '#FAFAFA',
              borderColor: '#EDF1F6',
              color: '#0A2540',
            }}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading || isLoadingParamTournament ? (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      sx={{
                        background: '#ffff',
                        zIndex: '99',
                        marginRight: '-14px',
                        borderRadius: '50%',
                      }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <CustomButton
        variant="menu"
        size="medium"
        sx={{
          width: '40px',
          backgroundColor: '#FAFAFA',
          border: '1px solid #EFEFF0',
          borderRadius: '8px',
          color: '#86909F',
          '&:hover': {
            backgroundColor: '#FAFAFA',
          },
        }}
        onClick={handleCopyLink}
      >
        <ExternalLink sx={{ color: '#86909F', fontSize: '20px' }} />
      </CustomButton>
    </Box>
  ) : (
    <Fragment>
      <Button
        sx={{
          width: '100%',
          mt: 3,
          background: open ? '#FF5733' : '#FAFAFA',
          border: '1px solid #EFEFF0',
          borderRadius: '8px',
        }}
        id="tournaments-menu"
        aria-controls="tournaments-menu2"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setOpen(true);
        }}
      >
        <ChevronDown style={{ color: open ? '#FFFF' : '#86909F' }} />
      </Button>
      <Menu
        id="tournaments-menu2"
        aria-labelledby="tournaments-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '250px',
            maxHeight: '400px',
            overflowY: 'auto',
            padding: '10px',
          },
        }}
      >
        {tournaments?.map((item: Tournament, index: number) => (
          <ListItem
            key={index}
            ref={lastOptionElementRef}
            button
            sx={{
              '& .MuiTypography-root': {
                fontWeight: '500',
                color: item.id === selectedTournament?.id ? '#FF5733' : '#86909F',
              },
            }}
            // onClick={() => handleGetId(item.id) , handleGetCount(item.id)}
            onClick={() => {
              navigate(`${location.pathname}?tournament=${item?.id}`);

              setLeague(item?.league?.league);
              setSelectedTournament(item);
              setTournamentId(item?.id, item?.league?.league?.id);
            }}
          >
            <ListItemText
              primary={`${item.title} [${moment(item?.startDate).format('DD MMMM YYYY')} - ${moment(item?.endDate).format('DD MMMM YYYY')}]`}
              sx={{
                color: '#0A2540',
                fontWeight: '500',
                justifyContent: 'flex-start',
                textAlign: 'left',
                paddingLeft: '16px',
                textTransform: 'none',
                width: '100%',
              }}
            />
          </ListItem>
        ))}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
            <CircularProgress size={18} />
          </Box>
        )}
      </Menu>
    </Fragment>
  );
};

export default TournamentSelection;
