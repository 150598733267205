import { TableCell, withStyles } from '@material-ui/core';

const CustomTableCell = withStyles({
  root: {
    padding: '10px 16px',
    borderColor: '#EDF1F6',
    color: '#86909F',
    fontSize: '12px',
  },
})(TableCell);

export default CustomTableCell;
