import { Box, Stack } from '@material-ui/core';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomPopover from 'src/components/CustomComponents/CustomPopover';
import React, { ComponentProps, ReactNode, useMemo, useState } from 'react';
import ChevronDown from '../../icons/ChevronDown';
import ClearButton from './ClearButton';

type Option<T> = {
  value: T;
  title: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
};

type CustomSelectProps<T> = {
  placeholder?: string;
  options: Option<T>[];
  optionsButtonProps?: Partial<ComponentProps<typeof CustomButton>>;
  selectedValue?: T;
  onChange: (value: T) => void;
  selected?: boolean;
  error?: boolean;
  helperText?: string;
  buttonProps?: Partial<ComponentProps<typeof CustomButton>>;
  onClear?: () => void;
  matchTriggerWidth?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  noOptionsText?: string;
};

const CustomSelect = <T extends string | number>({
  selectedValue,
  options,
  placeholder,
  error,
  helperText,
  onChange,
  selected,
  buttonProps = {},
  onClear,
  matchTriggerWidth = false,
  leftIcon,
  rightIcon,
  noOptionsText,
  optionsButtonProps,
}: CustomSelectProps<T>) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null); // Track index internally

  // Find selected option by value and index
  const selectedOption = useMemo(() => {
    if (selectedIndex !== null) {
      return options[selectedIndex];
    }
    return options?.find((option) => option.value === selectedValue);
  }, [selectedIndex, selectedValue, options]);

  const handleMenuItemClick = (value: T, index: number) => {
    setSelectedIndex(index); // Set the selected index to ensure uniqueness
    onChange(value); // Keep the `onChange` the same, passing only the `value`
  };

  return (
    <CustomPopover
      modalOnMobile
      disablePadding
      matchTriggerWidth={matchTriggerWidth}
      triggerEl={(open) => (
        <CustomButton
          size="small"
          variant="outline"
          selected={open || selected}
          {...buttonProps}
          error={error}
          helperText={helperText}
          sx={{
            gap: '4px',
            justifyContent: 'space-between',
            flexShrink: '0',
            position: 'relative',
            // width: '100%',
            ...buttonProps.sx,
          }}
        >
          <Box sx={{ display: 'flex', flex: 1, textAlign: 'start', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px', width: '100%', gap: '4px' }}>
            {leftIcon || selectedOption?.leftIcon}
            {selectedOption?.title ?? placeholder}
          </Box>
          <Stack flexDirection="row" gap="4px" alignItems="center">
            <ChevronDown sx={{ fontSize: '14px' }} />
            {selectedValue && onClear && <ClearButton onClear={onClear} />}
          </Stack>
          {rightIcon || selectedOption?.rightIcon}
        </CustomButton>
      )}
    >
      {(onClose) => (
        <Box sx={{ flex: 1, overflow: 'auto', maxHeight: { xs: 'none', sm: '240px' }, padding: '4px', minWidth: '100px', width: '100%' }}>
          {options?.length > 0 ? (
            options?.map((option, index) => (
              <li key={index} style={{ all: 'unset' }}>
                <CustomButton
                  variant="ghost"
                  size="medium"
                  sx={{ gap: '4px', width: '100%', overflow: 'hidden', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', height: '30px' }}
                  value={option.value}
                  {...optionsButtonProps}
                  onClick={() => {
                    onClose();
                    handleMenuItemClick(option.value, index);
                  }}
                  selected={selectedIndex === index || (selectedValue === option.value && selectedIndex === null)}
                  disabled={option.disabled}
                >
                  <Box sx={{ display: 'flex', gap: '4px', textOverflow: 'ellipsis', alignItems: 'center', flex: 1 }}>
                    {option?.leftIcon}
                    {option.title}
                  </Box>
                  {option?.rightIcon}
                </CustomButton>
              </li>
            ))
          ) : (
            <Box sx={{ textAlign: 'center', padding: '6px' }}>{noOptionsText || 'No options available'}</Box>
          )}
        </Box>
      )}
    </CustomPopover>
  );
};

export default CustomSelect;
