import { gql } from '@apollo/client';

const ADD_DRAW_ENTRY = gql`
  mutation addDrawEntry(
    $id: Int!
    $entry1: Int
    $entry2: Int
    $score: String
    $winner: Int
    $draw: Int
    $entry1pig: Int
    $entry2pig: Int
    $entry1group: String
    $entry2group: String
    $entry1groupId: Int
    $entry2groupId: Int
  ) {
    addDrawEntry(
      updateDrawEntryInput: {
        id: $id
        entry1: $entry1
        entry2: $entry2
        score: $score
        winner: $winner
        draw: $draw
        entry1pig: $entry1pig
        entry2pig: $entry2pig
        entry1group: $entry1group
        entry2group: $entry2group
        entry1groupId: $entry1groupId
        entry2groupId: $entry2groupId
      }
    ) {
      id
      entry1pig
      entry2pig
      entry1group
      entry2group
      entry1groupId {
        id
      }
      entry2groupId {
        id
      }
      entry1 {
        id
        additionalSegment
        assignedSegment
        seed
        team {
          title
          country
          image
        }
        users {
          id
          rank
          blitzElo
          rapidElo
          standardElo
          status
          isDelete
          createdAt
          updatedAt
          withdrawReason
          fideTitle
          user {
            name
            surname
            nation
            avatar
            citizenship
            email
            phone
            dob
            id
          }
        }
      }
      entry2 {
        id
        additionalSegment
        assignedSegment
        seed
        team {
          title
          country
          image
        }
        users {
          id
          rank
          blitzElo
          rapidElo
          standardElo
          status
          isDelete
          createdAt
          updatedAt
          withdrawReason
          fideTitle
          user {
            name
            surname
            nation
            avatar
            citizenship
            email
            phone
            dob
            id
          }
        }
      }
    }
  }
`;

export default ADD_DRAW_ENTRY;
