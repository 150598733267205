import { gql } from '@apollo/client';

const REMOVE_TOURNAMENT = gql`
  mutation removeTournament($id: Float!) {
    removeTournament(id: $id) {
      title
    }
  }
`;

export default REMOVE_TOURNAMENT;
