import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PopupWarningProps {
  openWarningModal: boolean;
  handleClose: () => void;
  handleAction: () => void;
  warningText: string;
  warningTitle?: string;
  confirmText?: string;
  cancelText?: string;
}

const WarningPopup = ({ openWarningModal, handleClose, handleAction, warningText, warningTitle, confirmText, cancelText }: PopupWarningProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={openWarningModal} onClose={handleClose} sx={{ zIndex: '99999' }}>
      <DialogTitle sx={{ '& .MuiTypography-root': { display: 'flex', alignItems: 'center', gap: '10px' } }}>
        <Warning color="action" sx={{ mt: -0.2 }} />
        {warningTitle ? warningTitle : t('Confirm Deletion')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{warningText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelText || t('Cancel')}
        </Button>
        <Button onClick={handleAction} sx={{ color: 'red' }}>
          {confirmText ? confirmText : t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningPopup;
