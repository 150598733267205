import { Box, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { ChevronRight, Info } from '@material-ui/icons';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import CustomTableCell from 'src/components/CustomComponents/CustomTableCell';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import GetFlag from 'src/components/shared/GetFlag';

const data = [
  {
    flag: 'Latvia',
    avatar: 'https://via.placeholder.com/18',
    name: 'Susan Clark',
    category: 'Men U12',
    action: 'Updated Match',
    type: 'Match teams',
    status: 'Confirmed',
    actionColor: 'info.main',
    date: '29 Jun 2020',
    time: '21:37',
  },
  // {
  //   flag: 'Pakistan',
  //   avatar: 'https://via.placeholder.com/18',
  //   name: 'Susan Clark',
  //   category: 'Men U12',
  //   action: 'Deleted entry',
  //   type: 'Entry players and category',
  //   actionColor: 'error.main',
  //   date: '29 Jun 2020',
  //   time: '21:37',
  // },
  // {
  //   flag: 'India',
  //   avatar: 'https://via.placeholder.com/18',
  //   name: 'Kaminskiy Robert',
  //   category: 'Men U12',
  //   action: 'Updated sorting',
  //   type: 'Category name',
  //   actionColor: 'success.main',
  //   date: '29 Jun 2020',
  //   time: '21:37',
  // },
  // {
  //   flag: 'Latvia',
  //   avatar: 'https://via.placeholder.com/18',
  //   name: 'Susan Clark',
  //   category: 'Men U12',
  //   action: 'Updated tournament details',
  //   type: 'Tournament name',
  //   actionColor: 'success.main',
  //   date: '29 Jun 2020',
  //   time: '21:37',
  // },
  // {
  //   flag: 'Pakistan',
  //   avatar: 'https://via.placeholder.com/18',
  //   name: 'Susan Clark',
  //   category: 'Men U12',
  //   action: 'Create group',
  //   type: 'Category names and group names',
  //   actionColor: 'info.main',
  //   date: '29 Jun 2020',
  //   time: '21:37',
  // },
];

const ActivityLogs = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        p: '15px',
        borderRadius: '8px',
        border: 1,
        borderColor: '#edf1f6',
        position: 'relative',
        background: 'white',
        height: '100%',
      }}
    >
      <Box display="flex" justifyContent="space-between" pb={1} alignItems="center">
        <Typography
          variant="h6"
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            fontFamily: 'Inter, sans-serif',
            color: '#0A2540',
          }}
        >
          Logs
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <CustomButton variant="text" size="small" sx={{ fontWeight: '300', fontSize: '13px' }} onClick={() => toast.success('This feature is still under development')}>
            view all
          </CustomButton>{' '}
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell sx={{ pt: '20px !important' }}>User</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Role</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Action</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Type</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}>Timestamp</CustomTableCell>
              <CustomTableCell sx={{ pt: '20px !important' }}></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index} hover sx={{ backgroundColor: index % 2 ? '#FBFBFB' : 'white' }}>
                <CustomTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    {/* <GetFlag
                      country={item.flag}
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      imgStyle={{
                        borderRadius: '5px',
                      }}
                    /> */}
                    {/* <CustomAvatar src={null} seed={item.name} size={18} /> */}
                    <Typography variant="font15" color="#0A2540">
                      {/* {item.name} */}-
                    </Typography>
                  </Box>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography color="#0A2540" fontSize="12px">
                    {/* Referee */}-
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography color={item.actionColor} fontSize="12px">
                    {/* {item.action} */}-
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography color="#0A2540" fontSize="12px">
                    {/* {item.type} */}-
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography color="#0A2540" fontSize="12px">
                    {/* {item.date} */}-
                  </Typography>
                  <Typography variant="body2" color="textSecondary" fontSize="10px">
                    {/* {item.time} */}-
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <CustomButton variant="text" size="small" onClick={() => toast.success('Under development')}>
                    <Info sx={{ fontSize: '16px', color: 'rgba(10, 37, 64, 0.35)' }} />
                  </CustomButton>
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ActivityLogs;
