import React, { FC, Fragment, useState } from 'react';
import CustomButton from '../CustomComponents/CustomButton';
import { Team, TournamentTeamMember } from 'src/__generated__/graphql';
import { Box, Divider, Stack, Typography } from '@material-ui/core';
import CustomChip from '../CustomComponents/CustomChip';
import PeopleIcon from '@material-ui/icons/People';
import CustomModal from '../CustomComponents/CustomModal';
import UserName from './UserName';
import useAuth from 'src/hooks/useAuth';

interface TeamComponentProps {
  users: TournamentTeamMember[];
  team?: Team;
  maxWidth?: string;
  highlightWinner?: boolean;
  fontSize?: string;
  showTeamMembers?: boolean;
  highlightColor?: string;
  shouldDisplayDupr?: boolean;
}

const TeamComponent: FC<TeamComponentProps> = ({ team, users, maxWidth = '200px', highlightWinner, fontSize = '12px', showTeamMembers = true, highlightColor = 'initial' }) => {
  const [openModal, setOpenModal] = useState(false);
  const { tournament } = useAuth();

  const shouldDisplayDupr = tournament?.isDuprRatingEnabled && tournament?.league?.league?.sports?.title?.toLowerCase() === 'pickleball';
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <Fragment>
      <CustomButton
        size="auto"
        variant="text"
        sx={{
          gap: '5px',
          ':hover': {
            background: !showTeamMembers ? 'transparent !important' : '',
          },
        }}
        onClick={() => {
          showTeamMembers ? (showTeamMembers ? setOpenModal(true) : null) : null;
        }}
      >
        {team?.image && <img src={team?.image} style={{ height: '25px', width: '25px', borderRadius: '6px' }} />}

        <Typography
          variant="tableFont"
          sx={{
            maxWidth: maxWidth,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: highlightWinner ? 'bold' : 'normal',
            color: highlightWinner ? highlightColor : 'initial',
            fontSize: fontSize,
          }}
        >
          {team?.title || 'X'}
        </Typography>
        {showTeamMembers && team && (
          <CustomChip size="xs" variant="secondary" square sx={{ maxHeight: '20px', maxWidth: '20px', minHeight: '20px', minWidth: '20px' }}>
            <PeopleIcon sx={{ fontSize: '16px' }} />
          </CustomChip>
        )}
      </CustomButton>
      <CustomModal openOvveride={openModal} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" title={'Team members'}>
        <Box sx={{ maxHeight: { xs: 'none', sm: '250px' }, overflow: 'auto', py: '10px' }}>
          {users?.length > 0 ? (
            <Stack gap="8px">
              {users?.map((item, i) => <UserName user={item} shouldDisplayDupr={shouldDisplayDupr} />)}
              {shouldDisplayDupr && (
                <>
                  <Divider />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                    <Typography sx={{ color: '#86909F', fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>T.DUPR:</Typography>
                    <Typography sx={{ fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal' }}>
                      {` ${users
                        ?.map((user) => user?.duprRating || 0)
                        ?.reduce((acc, rating) => acc + rating, 0)
                        ?.toFixed(3)}`}
                    </Typography>
                  </Box>
                </>
              )}
            </Stack>
          ) : (
            'There is no available members!'
          )}
        </Box>
      </CustomModal>
    </Fragment>
  );
};

export default TeamComponent;
