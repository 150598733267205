import { gql } from '@apollo/client';

const DELETE_MANUAL_MATCH = gql`
  mutation removeMatch($id: Int!) {
    removeMatch(id: $id) {
      id
    }
  }
`;

export default DELETE_MANUAL_MATCH;
