// import { gql } from "@apollo/client";

// const UPLOAD_DRAWS = gql`
//   mutation createDraw(
//     $drawImage: Upload!
//     $tournament: Int!
//     $fileName: String!
//   ) {
//     createDraw(
//       createDrawInput: {
//         drawImage: $drawImage
//         tournament: $tournament
//         fileName: $fileName
//       }
//     ) {
//       id
//     }
//   }
// `;

// export default UPLOAD_DRAWS;

import { gql } from '@apollo/client';

const CREATE_DRAWS = gql`
  mutation createDraw(
    $tournament: Int!
    $tournamentCategory: Int!
    $title: String!
    $size: Int!
    $type: String
    $algo: String
    $segment: String!
    $seeds: Int
    $qualWinners: Int
    $genDraw: String!
    $liveStanding: Int
    $startLiveStandings: Int
    $endLiveStandings: Int
    $removeDrawId: Int
    $thirdPlaceDraw: Boolean
    $drawEntries: [Int!]
    $consolationDrawEntries: String
    $consolationMainDraw: Int
    $isDistributeClubMembers: Boolean
    $isDisplayParticipantsClub: Boolean
    $groupIds: [Int!]
  ) {
    createDraw(
      createDrawInput: {
        tournament: $tournament
        tournamentCategory: $tournamentCategory
        title: $title
        size: $size
        type: $type
        algo: $algo
        segment: $segment
        seeds: $seeds
        qualWinners: $qualWinners
        genDraw: $genDraw
        liveStanding: $liveStanding
        startLiveStandings: $startLiveStandings
        endLiveStandings: $endLiveStandings
        removeDrawId: $removeDrawId
        thirdPlaceDraw: $thirdPlaceDraw
        drawEntries: $drawEntries
        consolationDrawEntries: $consolationDrawEntries
        consolationMainDraw: $consolationMainDraw
        isDistributeClubMembers: $isDistributeClubMembers
        isDisplayParticipantsClub: $isDisplayParticipantsClub
        groupIds: $groupIds
      }
    ) {
      id
    }
  }
`;

export default CREATE_DRAWS;
