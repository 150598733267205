import { gql } from '@apollo/client';

const CREATE_MATCH = gql`
  mutation createMatch($entry1: Int!, $entry2: Int!, $tournament: Int!, $tournamentCategory: Int!, $court: Int, $time: String, $date: DateTime) {
    createMatch(createMatchInput: { entry1: $entry1, entry2: $entry2, tournament: $tournament, tournamentCategory: $tournamentCategory, court: $court, time: $time, date: $date }) {
      id
    }
  }
`;

export default CREATE_MATCH;
