import { gql } from '@apollo/client';

const ALL_LEAGUES = gql`
  query allLeaguesForManager {
    allLeaguesForManager(filter: {}) {
      title
      type
      status
      country
      sports {
        id
        title
        resourceTitle
        discipline {
          id
          title
          name
          resultType
        }
      }
      email
      website
      id
      logo
      owner {
        id
        federation {
          id
        }
      }
      abbreviationLogo
      athlete {
        athlete {
          email
          dob
        }
      }
      tournaments {
        id
      }
      platform {
        lightLogo
        darkLogo
        id
        title
        currency
        platformLink
        timeZone
        stripeAccountId
      }
    }
  }
`;

export default ALL_LEAGUES;
